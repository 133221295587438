import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ReportRequest, Song } from 'src/app/shared/models';
import { DialogService, ProcessManagerService, ReportService, SongService } from 'src/app/shared/services';

@Component({
  selector: 'app-report-song-details',
  templateUrl: './report-song-details.component.html'
})
export class ReportSongDetailsComponent {
  // variables

  public reportRequestForm: FormGroup;
  public reportTitle: string = 'Song Details';
  public songs: Song[] = [];

  // constructor

  constructor(private dialogService: DialogService, public formBuilder: FormBuilder, private processManagerService: ProcessManagerService, private reportService: ReportService, private songService: SongService,
    public snackBar: MatSnackBar) {
  }

  // event handlers

  ngOnInit() {
    this.processManagerService.addProcesses(['report']);
    this.processManagerService.unforce();

    this.initializeReportRequestForm();
  }

  // business logic

  public clickResetSearch() {
    this.initializeReportRequestForm();
    this.songs = [];
  }

  public clickViewChart(song: Song) {
    let fileName: string = this.songService.returnChartFileName(song.title);

    this.songService.loadSongChart(song.id).subscribe(result => {
      this.songService.viewChart(result, fileName);
    }, error => {
      this.displayError(error.message);
    });
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, this.reportTitle);
  }

  private initializeReportRequestForm() {
    var dateEnd: Date = new Date();
    var year = dateEnd.getFullYear();
    var month = dateEnd.getMonth();
    var day = dateEnd.getDate();
    dateEnd = new Date(year, month, day);
    month -= 3;
    var dateStart: Date = new Date(year, month, day);

    this.reportRequestForm = this.formBuilder.group({
      dateEnd: [dateEnd, []],
      dateStart: [dateStart, []],
    });
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  public submitReportRequest() {
    var reportRequest: ReportRequest = new ReportRequest();
    reportRequest.dateEnd = this.reportRequestForm.get('dateEnd').value;
    reportRequest.dateStart = this.reportRequestForm.get('dateStart').value;

    this.processManagerService.resetProcess('report');
    this.reportService.loadReportSongDetails(reportRequest).subscribe(result => {
      this.processManagerService.notify('report');
      this.songs = result;

      if (this.songs.length == 0) {
        this.snackBar.open('There is no report data for the selected time period.', '', {
          duration: 2000,
        });
      }
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }
}
