import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../common/dialog/dialog.component';
import { DialogData } from '../../shared/models/dialog-data.model';

@Injectable()
export class DialogService {
  // constructors

  constructor(private dialog: MatDialog) {
  }

  // business logic

  public displayError(text: string, title: string) {
    var dialogData: DialogData = new DialogData(false, text, title);
    this.openDialog(dialogData);
  }

  public openDialog(dialogData: DialogData): any {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '600px',
      data: dialogData
    });

    return dialogRef.afterClosed();
  }
}
