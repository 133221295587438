import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatCheckboxChange } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, ProcessManagerService, ScheduleService } from 'src/app/shared/services';
import { ScheduleEmail, User, Role } from '../../shared';

@Component({
  selector: 'app-send-schedule-email',
  templateUrl: './send-schedule-email.component.html'
})
export class SendScheduleEmailComponent {
  // variables

  private dateSelected: Date;
  public pageMessage: string = '';
  public pageTitle: string = 'Send Email';
  public roleId: number = 0;
  private routing: any;
  public scheduleEmail: ScheduleEmail;
  public scheduleEmailForm: FormGroup;

  // constructor

  constructor(private datePipe: DatePipe, private dialogService: DialogService, public formBuilder: FormBuilder, private processManagerService: ProcessManagerService,
    private route: ActivatedRoute, private router: Router, private scheduleService: ScheduleService, public snackBar: MatSnackBar) {
  }

  // event handlers

  ngOnInit() {
    this.processManagerService.addProcesses(['scheduleEmail']);

    this.routing = this.route.params.subscribe(params => {
      if ((params['role'] != undefined) && (params['year'] != undefined) && (params['month'] != undefined)) {
        var role: string = params['role'];
        var year: number = parseInt(params['year']);
        var month: number = parseInt(params['month']);

        this.roleId = Role.MUSICIAN;
        if (role == 'media') {
          this.roleId = Role.MEDIA;
        }
        else if (role == 'sound') {
          this.roleId = Role.SOUND;
        }

        this.dateSelected = new Date(year, month - 1, 1);
        this.loadSchedule(this.roleId, year, month);
      }
      else {
        this.router.navigate(['/schedules']);
      }
    });
  }

  // business logic

  private addUsers() {
    if (this.scheduleEmail != null) {
      var formArray: FormArray = (this.scheduleEmailForm.get('users') as FormArray);

      for (var i: number = 0; i < this.scheduleEmail.users.length; i++) {
        var user = this.scheduleEmail.users[i];
        var selected: boolean = user.selected;
        var formControl = new FormControl(selected);
        formArray.controls.push(formControl);

        if (selected) {
          this.scheduleEmailForm.get('hasSelectedUsers').setValue(true);
        }
      }
    }
  }

  public clickCancel() {
    this.router.navigate(['/schedules']);
  }

  public changeSelectedUser() {
    var formArray: FormArray = (this.scheduleEmailForm.get('users') as FormArray);
    var selected: boolean = (formArray.controls.find(c => c.value) != undefined);

    if (selected) {
      this.scheduleEmailForm.get('hasSelectedUsers').setValue(true);
    }
    else {
      this.scheduleEmailForm.get('hasSelectedUsers').setValue(null);
    }
  }

  public clickClearSelectedUsers() {
    var formArray: FormArray = (this.scheduleEmailForm.get('users') as FormArray);

    for (var i: number = 0; i < formArray.controls.length; i++) {
      formArray.controls[i].setValue(false);
    }

    this.scheduleEmailForm.get('hasSelectedUsers').setValue(null);
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  private initializeForm() {
    this.scheduleEmailForm = this.formBuilder.group({
      hasSelectedUsers: [null, [Validators.required]],
      includeSchedule: [this.scheduleEmail.includeSchedule, []],
      optionalMessage: [null, []],
      users: this.formBuilder.array([]),
    });
    this.addUsers();
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  private loadSchedule(roleId: number, year: number, month: number) {
    this.processManagerService.force();
    this.scheduleService.loadScheduleEmail(roleId, year, month).subscribe(result => {
      this.scheduleEmail = result;
      this.processManagerService.unforce();
      this.initializeForm();

      this.pageTitle = 'Manage Schedule';
      this.pageMessage = 'To send an email alert to the personnel on the <strong>' + this.datePipe.transform(this.dateSelected, 'MMMM yyyy') + '</strong> schedule, check the appropriate personnel below, enter your personalized message, and click the <strong>Send Alert</strong> button.';
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  public returnScheduleEmail(): ScheduleEmail {
    var formArray: FormArray = (this.scheduleEmailForm.get('users') as FormArray);
    var users: User[] = [];
    for (var i: number = 0; i < formArray.length; i++) {
      if (formArray.controls[i].value) {
        users.push(this.scheduleEmail.users[i]);
      }
    }

    var scheduleEmail: ScheduleEmail = new ScheduleEmail();
    scheduleEmail.includeSchedule = this.scheduleEmailForm.get('includeSchedule').value;
    scheduleEmail.month = this.scheduleEmail.month;
    scheduleEmail.optionalMessage = this.scheduleEmailForm.get('optionalMessage').value;
    scheduleEmail.roleId = this.roleId;
    scheduleEmail.users = users;
    scheduleEmail.year = this.scheduleEmail.year;

    return scheduleEmail;
  }

  public submitSendScheduleEmail() {
    var scheduleEmail: ScheduleEmail = this.returnScheduleEmail();

    this.processManagerService.force();
    this.scheduleService.sendScheduleEmail(scheduleEmail).subscribe(result => {
      this.processManagerService.unforce();
      this.snackBar.open('The schedule for ' + this.datePipe.transform(this.dateSelected, 'MMMM yyyy') + ' has been sent.', '', {
        duration: 2000,
      });
      this.router.navigate(['/schedules']);
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }
}
