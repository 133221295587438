import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { MaterialModule } from '../material.module';
import { PipesModule } from '../pipes.module';
import { CompactScheduleComponent } from './compact-schedule/compact-schedule.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { MonthlyScheduleComponent } from './monthly-schedule/monthly-schedule.component';
import { SongStatisticsComponent } from './song-statistics/song-statistics.component';
import { UpcomingScheduleComponent } from './upcoming-schedule/upcoming-schedule.component';

export let options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    CompactScheduleComponent,
    DashboardComponent,
    EditProfileComponent,
    MonthlyScheduleComponent,
    SongStatisticsComponent,
    UpcomingScheduleComponent,
  ],
  exports: [
    CompactScheduleComponent,
    DashboardComponent,
    EditProfileComponent,
    MonthlyScheduleComponent,
    SongStatisticsComponent,
    UpcomingScheduleComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    MomentModule,
    NgxMaskModule.forRoot(options),
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class AppUserModule {}
