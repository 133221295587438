import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../globals';
import { DialogService, ErrorService, ProcessManagerService, TokenService, UserPasswordReset, UserService } from '../../shared';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {
  // variables

  public pageMessage: string = '';
  public pageTitle: string = 'Forgot Password';
  public passwordSent: boolean = false;
  public recaptchaSiteKey: string = this.globals.recaptchaSiteKey;
  private subscription: any;
  public userPasswordReset: UserPasswordReset;
  public userPasswordResetForm: FormGroup;

  // constructor

  constructor(private dialogService: DialogService, private errorService: ErrorService, public formBuilder: FormBuilder, private globals: Globals, private processManagerService: ProcessManagerService,
    private route: ActivatedRoute, private router: Router, private tokenService: TokenService, private userService: UserService) {
  }

  // event handlers

  ngOnInit() {
    if (this.tokenService.isTokenExpired()) {
      this.processManagerService.addProcesses(['forgotPassword']);
      this.initializeNewUserPasswordReset();
    }
    else {
      this.router.navigate(['/']);
    }
    this.subscription = this.route.params.subscribe(params => {
      var emailAddress: string = params['emailAddress'];
      if (emailAddress != null) {
        this.userPasswordResetForm.get('emailAddress').setValue(emailAddress);
      }
    });
  }

  // business logic

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  public getError(controlName, typeName, formGroupName) {
    return this.errorService.getError(this.userPasswordResetForm, controlName, typeName, formGroupName);
  }

  public handleRecaptchaExpire() {
    this.userPasswordResetForm.get('recaptchaResponse').setValue('');
  }

  public handleRecaptchaLoad() {
  }

  public handleRecaptchaReset() {
    this.userPasswordResetForm.get('recaptchaResponse').setValue('');
  }

  public handleRecaptchaSuccess(event) {
  }

  private initializeForm() {
    this.userPasswordResetForm = this.formBuilder.group({
      emailAddress: [this.userPasswordReset.emailAddress, [Validators.required, Validators.maxLength(80)]],
      recaptchaResponse: ['', [Validators.required]],
    });
    this.userPasswordResetForm.setValidators([]);
  }

  private initializeNewUserPasswordReset() {
    this.pageMessage = 'To reset your password, enter your <strong>Email Address</strong> and click <strong>Send</strong>.';
    this.userPasswordReset = this.userService.returnNewUserPasswordReset();
    this.processManagerService.notify('forgotPassword');
    this.initializeForm();
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  public submitUserPasswordReset() {
    this.updateUserPasswordReset();

    this.processManagerService.force();

    this.userService.sendUserForgotPassword(this.userPasswordReset).subscribe(result => {
      this.processManagerService.unforce();
      this.passwordSent = true;
    }, error => {
      this.processManagerService.unforce();
      if ((error as HttpErrorResponse).status == 401) {
        this.displayError('The Google Recaptcha has expired. Please try again.');
      }
      else {
        this.displayError(error.message);
      }
    });
  }

  private updateUserPasswordReset() {
    this.userPasswordReset.emailAddress = this.userPasswordResetForm.get('emailAddress').value;
    this.userPasswordReset.recaptchaResponse = this.userPasswordResetForm.get('recaptchaResponse').value;
  }
}
