import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { Router } from '@angular/router';
import { Event, EventSearch, PermissionFlag, User } from 'src/app/shared/models';
import { DialogService, EventService, ProcessManagerService, TokenService } from 'src/app/shared/services';

@Component({
  selector: 'app-list-events',
  templateUrl: './list-events.component.html'
})
export class ListEventsComponent {
  // variables

  public displayedColumns: string[] = ['dateEvent', 'users', 'hasSongs', 'edit'];
  public events: MatTableDataSource<Event> = new MatTableDataSource<Event>();
  public eventSearchForm: FormGroup;
  public isAdministrator: boolean = false;
  public pageMessage: string = '';
  public pageTitle: string = 'Manage Events';
  private paginator: MatPaginator;
  private searchStorageKey: string = 'eventSearch';
  private sort: MatSort;

  // properties

  @ViewChild(MatPaginator, { static: false }) set matPaginator(value: MatPaginator) {
    this.paginator = value;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort, { static: false }) set matSort(value: MatSort) {
    this.sort = value;
    this.setDataSourceAttributes();
  }

  // constructor

  constructor(private dialogService: DialogService, private eventService: EventService, public formBuilder: FormBuilder, private processManagerService: ProcessManagerService,
    private router: Router, public snackBar: MatSnackBar, private tokenService: TokenService) {
    this.isAdministrator = this.tokenService.canAccessArea(PermissionFlag.EVENTS);
  }

  // event handlers

  ngOnInit() {
    this.processManagerService.addProcesses(['events']);

    this.initializeSearchForm();
    let eventSearch: EventSearch = this.loadEventSearch();
    if (eventSearch == null) {
      this.loadEvents(this.eventService.returnNewEventSearch());
    }
    else {
      this.loadEvents(eventSearch);
    }
  }

  // business logic

  public clickResetSearch() {
    this.initializeSearchForm();
    this.loadEvents(this.eventService.returnNewEventSearch());
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  private initializeSearchForm() {
    var eventSearch: EventSearch = this.eventService.returnNewEventSearch();

    this.eventSearchForm = this.formBuilder.group({
      dateEnd: [eventSearch.dateEnd, []],
      dateStart: [eventSearch.dateStart, []],
      eventFlag: [eventSearch.eventFlag, []],
    });
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  private loadEvents(eventSearch: EventSearch) {
    this.processManagerService.resetProcess('events');
    this.eventService.searchEvents(eventSearch).subscribe(result => {
      this.processManagerService.notify('events');
      this.loadEventResults(result, eventSearch);
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  private loadEventSearch(): EventSearch {
    var eventSearch: EventSearch = JSON.parse(sessionStorage.getItem(this.searchStorageKey));

    if (eventSearch != null) {
      if (eventSearch.dateEnd != null) {
        this.eventSearchForm.get('dateEnd').setValue(eventSearch.dateEnd);
      }
      if (eventSearch.dateStart != null) {
        this.eventSearchForm.get('dateStart').setValue(eventSearch.dateStart);
      }
      if (eventSearch.eventFlag != null) {
        this.eventSearchForm.get('eventFlag').setValue(eventSearch.eventFlag);
      }
    }

    return eventSearch;
  }

  private loadEventResults(result: Event[], eventSearch: EventSearch) {
    this.events = new MatTableDataSource<Event>(result);

    this.pageMessage = 'These are the events in the database.';
    if (this.events.data.length == 0) {
      if ((eventSearch == null) || eventSearch.isEmpty()) {
        this.pageMessage = 'There are no events in the database.';
      }
      else {
        this.pageMessage = 'There are no events in the database that match your search criteria.';
      }
    }
  }

  public returnLeaders(users: User[]): string {
    var items: string[] = [];

    for (var i: number = 0; i < users.length; i++) {
      items.push(users[i].name);
    }

    return items.join(', ');
  }

  private setDataSourceAttributes() {
    setTimeout(() => {
      this.events.paginator = this.paginator;
      this.events.sort = this.sort;
      if (this.sort != undefined) {
        this.sort.sortChange.subscribe((sort: Sort) => {
          this.events.paginator.firstPage();
        });
      }
    });
  }

  public submitEventSearch() {
    var eventSearch: EventSearch = new EventSearch();
    eventSearch.dateEnd = this.eventSearchForm.get('dateEnd').value;
    eventSearch.dateStart = this.eventSearchForm.get('dateStart').value;
    eventSearch.eventFlag = this.eventSearchForm.get('eventFlag').value;

    sessionStorage.setItem(this.searchStorageKey, JSON.stringify(eventSearch));

    this.loadEvents(eventSearch);
  }
}
