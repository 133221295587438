import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User, Role } from '../../shared/models';
import { ErrorService, UserService } from '../../shared/services';

@Component({
  selector: 'app-dialog-guest',
  templateUrl: './dialog-guest.component.html'
})
export class DialogGuestComponent {
  // variables

  public dialogMessage: string = '';
  public dialogTitle: string = '';
  public roleId: number;
  public user: User;
  public userForm: FormGroup;

  // constructor

  constructor(public dialogRef: MatDialogRef<DialogGuestComponent>, private errorService: ErrorService, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: DialogGuestData,
    private userService: UserService) {
  }

  // event handlers

  ngOnInit() {
    this.roleId = this.data.roleId;
    this.user = this.data.user;
    this.initializeForm();

    var type: string = 'Musician';
    if (this.roleId == Role.MEDIA) {
      type = 'Media';
    }
    else if (this.roleId == Role.SOUND) {
      type = 'Sound';
    }

    this.dialogMessage = 'Update the guest ' + type.toLowerCase() + ' information and click the the <strong>OK</strong> button.';
    this.dialogTitle = 'Guest ' + type;
  }

  // business logic

  public clickCancel() {
    this.dialogRef.close();
  }

  public clickOk() {
    var user: User = this.userService.returnNewUser();
    user.emailAddress = this.userForm.get('emailAddress').value;
    user.firstName = this.userForm.get('firstName').value;
    user.lastName = this.userForm.get('lastName').value;

    this.dialogRef.close({ response: user });
  }

  public getError(controlName, typeName) {
    return this.errorService.getError(this.userForm, controlName, typeName, undefined);
  }

  private initializeForm() {
    this.userForm = this.formBuilder.group({
      emailAddress: [this.user.emailAddress, [Validators.email]],
      firstName: [this.user.firstName, [Validators.required]],
      lastName: [this.user.lastName, [Validators.required]],
    });
    if (this.user.isGuest) {
      if (this.user.emailAddress == User.GUEST_EMAIL_ADDRESS) {
        this.userForm.get('emailAddress').setValue(null);
      }
      if (this.user.firstName == User.GUEST_FIRST_NAME) {
        this.userForm.get('firstName').setValue(null);
      }
      if (this.user.lastName == User.GUEST_LAST_NAME) {
        this.userForm.get('lastName').setValue(null);
      }
    }
  }
}

export class DialogGuestData {
  // variables

  roleId: number;
  user: User;

  // constructor

  constructor(roleId: number, user: User) {
    this.roleId = roleId;
    this.user = user;
  }
}
