import { MonthlyScheduleEvent } from './monthly-schedule-event.model';
import { MonthlyScheduleUser } from './monthly-schedule-user.model';
import { Role } from './role.model';

export class MonthlySchedule {
  // variables

  events: MonthlyScheduleEvent[];
  month: number;
  role: Role;
  users: MonthlyScheduleUser[];
  year: number;
}
