import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventSearch, EventFlag } from '../models';
import { Event } from '../models/event.model';

@Injectable()
export class EventService {
  // variables

  private serviceUrl: string = 'api/Events';

  // constructors

  constructor(private http: HttpClient) {
  }

  // business logic

  public loadEvent(id: number): Observable<Event> {
    return this.http.get<Event>(this.serviceUrl + '/LoadEvent/' + id.toString());
  }

  public modifyEvent(event: Event): Observable<Event> {
    return this.http.put<Event>(this.serviceUrl + '/ModifyEvent', event);
  }

  public returnNewEventSearch(): EventSearch {
    var dateEnd: Date = new Date();
    var dateStart: Date = new Date();
    dateStart.setDate(dateStart.getDate() - 90);

    var eventSearch: EventSearch = new EventSearch();
    eventSearch.dateEnd = dateEnd;
    eventSearch.dateStart = dateStart;
    eventSearch.eventFlag = EventFlag.ALL;

    return eventSearch;
  }

  public searchEvents(eventSearch: EventSearch): Observable<Event[]> {
    return this.http.post<Event[]>(this.serviceUrl + '/LoadEvents', eventSearch);
  }
}
