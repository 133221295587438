import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DndModule } from 'ng2-dnd';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { MaterialModule } from '../material.module';
import { DialogGuestComponent } from './dialog-guest/dialog-guest.component';
import { ListEventsComponent } from './list-events/list-events.component';
import { ListSchedulesComponent } from './list-schedules/list-schedules.component';
import { ListSongsComponent } from './list-songs/list-songs.component';
import { ListUsersComponent } from './list-users/list-users.component';
import { ManageEventComponent } from './manage-event/manage-event.component';
import { ManageScheduleComponent } from './manage-schedule/manage-schedule.component';
import { ManageSongComponent } from './manage-song/manage-song.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { ReportSongDetailsComponent } from './report-song-details/report-song-details.component';
import { ReportSongSummaryComponent } from './report-song-summary/report-song-summary.component';
import { ScheduleEventComponent } from './schedule-event/schedule-event.component';
import { SendScheduleEmailComponent } from './send-schedule-email/send-schedule-email.component';
import { ViewReportsComponent } from './view-reports/view-reports.component';

export let options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    DialogGuestComponent,
    ListEventsComponent,
    ListSchedulesComponent,
    ListSongsComponent,
    ListUsersComponent,
    ManageEventComponent,
    ManageScheduleComponent,
    ManageSongComponent,
    ManageUserComponent,
    ReportSongDetailsComponent,
    ReportSongSummaryComponent,
    ScheduleEventComponent,
    SendScheduleEmailComponent,
    ViewReportsComponent,
  ],
  entryComponents: [
    DialogGuestComponent,
    ReportSongDetailsComponent,
    ReportSongSummaryComponent,
  ],
  exports: [
    DialogGuestComponent,
    DndModule,
    ListEventsComponent,
    ListSchedulesComponent,
    ListSongsComponent,
    ListUsersComponent,
    ManageEventComponent,
    ManageScheduleComponent,
    ManageSongComponent,
    ManageUserComponent,
    ReportSongDetailsComponent,
    ReportSongSummaryComponent,
    ScheduleEventComponent,
    SendScheduleEmailComponent,
    ViewReportsComponent,
  ],
  imports: [
    CommonModule,
    DndModule,
    FormsModule,
    MaterialModule,
    MomentModule,
    NgxMaskModule.forRoot(options),
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class AppAdminModule {}
