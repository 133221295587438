import { EventFlag } from "./event-flag.model";

export class EventSearch {
  // variables

  dateEnd: Date;
  dateStart: Date;
  eventFlag: number;

  // business logic

  public isEmpty(): boolean {
    if ((this.dateEnd == null) && (this.dateStart == null) && (this.eventFlag == EventFlag.ALL)) {
      return true;
    }

    return false;
  }
}
