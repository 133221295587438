export class DialogData {
  // variables

  isConfirm: boolean;
  text: string;
  title: string;

  // constructors

  constructor(isConfirm: boolean, text: string, title: string) {
    this.isConfirm = isConfirm;
    this.text = text;
    this.title = title;
  }
}
