import { Component, Input, SimpleChanges } from '@angular/core';
import { Event, MonthlyScheduleUserEvent, User, MonthlyScheduleEvent } from '../../shared/models';

@Component({
  selector: 'app-upcoming-schedule',
  templateUrl: './upcoming-schedule.component.html'
})
export class UpcomingScheduleComponent {
  // variables

  public events: Event[];

  // properties

  @Input() upcomingSchedule: Event[];

  // constructor

  constructor() {
  }

  // event handlers

  ngOnChanges(changes: SimpleChanges) {
    this.updateSchedule();
  }

  // business logic

  public returnInstrument(user: User): string {
    var instrument: string = '<span>' + user.instrument.name.toLowerCase() + '</span>';

    if (user.isLeader) {
      instrument += '<i class=\'material-icons\'>grade</i>';
    }

    return instrument;
  }

  private updateSchedule() {
    if (this.upcomingSchedule == undefined) {
      return;
    }

    var dates: Date[] = [];
    for (var i: number = 0; i < this.upcomingSchedule.length; i++) {
      var date: Date = this.upcomingSchedule[i].dateEvent;
      if (dates.indexOf(date) == -1) {
        dates.push(date);
      }
    }

    this.events = [];
    for (var i: number = 0; i < dates.length; i++) {
      let date: Date = dates[i];
      let event: Event = new Event();
      event.dateEvent = new Date(date);
      event.dateEvent.setTime(event.dateEvent.getTime() + event.dateEvent.getTimezoneOffset() * 60 * 1000);
      event.users = [];

      var users: User[] = [].concat.apply([], this.upcomingSchedule.filter(s => s.dateEvent == date).map(s => s.users));
      if (users.length > 0) {
        users.sort(function (u1, u2) {
          if (u1.lastName > u2.lastName) return 1;
          if (u1.lastName < u2.lastName) return -1;
          return 0;
        });
        event.users = users;
      }

      this.events.push(event);
    }
  }
}
