import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserPasswordReset } from '../../shared/models';
import { DialogService, ErrorService, ProcessManagerService, TokenService, UserService } from '../../shared/services';
import { PasswordMatchValidator } from '../../shared/validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent {
  // variables

  public pageMessage: string = '';
  public pageTitle: string = 'Reset Password';
  public passwordReset: boolean = false;
  public passwordResetKeyNotFound: boolean = false;
  private subscription: any;
  public userPasswordReset: UserPasswordReset;
  public userPasswordResetForm: FormGroup;

  // constructor

  constructor(private dialogService: DialogService, private errorService: ErrorService, public formBuilder: FormBuilder, private processManagerService: ProcessManagerService,
    private route: ActivatedRoute, private router: Router, private tokenService: TokenService, private userService: UserService) {
  }

  // event handlers

  ngOnInit() {
    if (this.tokenService.isTokenExpired()) {
      this.processManagerService.addProcesses(['resetPassword']);
    }
    else {
      this.router.navigate(['/']);
    }
    this.subscription = this.route.params.subscribe(params => {
      var passwordResetKey: string = params['passwordResetKey'];
      if (passwordResetKey != null) {
        this.loadUserPasswordReset(passwordResetKey);
      }
    });
  }

  // business logic

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  public getError(controlName, typeName, formGroupName) {
    return this.errorService.getError(this.userPasswordResetForm, controlName, typeName, formGroupName);
  }

  private initializeForm() {
    this.userPasswordResetForm = this.formBuilder.group({
      passwords: this.formBuilder.group({
        password: ['', [Validators.required, Validators.minLength(8)]],
        passwordConfirm: ['', [Validators.required]]
      })
    });
    this.userPasswordResetForm.get('passwords').setValidators([PasswordMatchValidator]);
    this.userPasswordResetForm.setValidators([]);
  }

  private initializeNewUserPasswordReset() {
    this.pageMessage = 'To reset your password, enter your new <strong>Password</strong> and click <strong>Reset</strong>.';
    this.processManagerService.notify('resetPassword');
    this.initializeForm();
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  public loadUserPasswordReset(passwordResetKey: string) {
    this.userService.loadUserPasswordReset(passwordResetKey).subscribe(result => {
      this.processManagerService.unforce();
      if (result != null) {
        this.userPasswordReset = result;
        this.initializeNewUserPasswordReset();
      }
      else {
        this.passwordResetKeyNotFound = true;
      }
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  public submitUserPasswordReset() {
    this.updateUserPasswordReset();

    this.processManagerService.force();

    this.userService.modifyUserPasswordReset(this.userPasswordReset).subscribe(result => {
      this.processManagerService.unforce();
      if (result != null) {
        this.pageMessage = '';
        this.passwordReset = true;
      }
      else {
        this.passwordResetKeyNotFound = true;
      }
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  private updateUserPasswordReset() {
    this.userPasswordReset.password = this.userPasswordResetForm.get('passwords').get('password').value;
  }
}
