import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService, PermissionFlag } from '../../shared';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  // variables

  isExpanded = false;

  // constructors

  constructor(private router: Router, private tokenService: TokenService) { }

  // business logic

  public canAccessEvents() {
    return this.tokenService.canAccessArea(PermissionFlag.EVENTS);
  }

  public canAccessReports() {
    return this.tokenService.canAccessArea(PermissionFlag.REPORTS);
  }

  public canAccessSchedules() {
    return this.tokenService.canAccessArea(PermissionFlag.SCHEDULES);
  }

  public canAccessSongs() {
    return this.tokenService.canAccessArea(PermissionFlag.SONGS);
  }

  public canAccessUsers() {
    return this.tokenService.canAccessArea(PermissionFlag.USERS);
  }

  public clickLogout() {
    this.tokenService.removeToken();
    this.router.navigate(['/login']);
  }

  public collapse() {
    this.isExpanded = false;
  }

  public hasAccess() {
    return this.tokenService.isAdminUser();
  }

  public isLoggedIn() {
    if (this.tokenService.isTokenExpired()) {
      return false;
    }

    return true;
  }

  public toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
