import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { AppAdminModule } from './admin/app-admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppCommonModule } from './common/app-common.module';
import { Globals } from './globals';
import { MaterialModule } from './material.module';
import { PipesModule } from './pipes.module';
import { UserAuthGuard } from './shared/guards/user-auth.guard';
import { DateService } from './shared/services/date.service';
import { DialogService } from './shared/services/dialog.service';
import { ErrorService } from './shared/services/error.service';
import { EventService } from './shared/services/event.service';
import { ProcessManagerService } from './shared/services/process-manager.service';
import { ReportService } from './shared/services/report.service';
import { ScheduleService } from './shared/services/schedule.service';
import { SongService } from './shared/services/song.service';
import { TokenService } from './shared/services/token.service';
import { UserService } from './shared/services/user.service';
import { AppUserModule } from './user/app-user.module';
import { DatePipe } from '@angular/common';
import { DndModule } from 'ng2-dnd';

export let options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export function tokenGetter() {
  return localStorage.getItem(TokenService.TOKEN_NAME);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppAdminModule,
    AppCommonModule,
    AppRoutingModule,
    AppUserModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    MaterialModule,
    MomentModule,
    NgxMaskModule.forRoot(options),
    PipesModule,
    ReactiveFormsModule,
    DndModule.forRoot(),
  ],
  providers: [
    DatePipe,
    DateService,
    DialogService,
    ErrorService,
    EventService,
    Globals,
    JwtHelperService,
    ProcessManagerService,
    ReportService,
    ScheduleService,
    SongService,
    TokenService,
    UserAuthGuard,
    UserService,
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule { }
