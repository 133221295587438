import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventFlag } from '../models/event-flag.model';
import { PermissionFlag } from '../models/permission-flag.model';
import { Role } from '../models/role.model';
import { UserLogin } from '../models/user-login.model';
import { UserPasswordReset } from '../models/user-password-reset.model';
import { UserSearch } from '../models/user-search.model';
import { User } from '../models/user.model';

@Injectable()
export class UserService {
  // variables

  private keyEmailAddress: string = 'RememberedEmailAddress';
  private serviceUrl: string = 'api/Users';

  // constructors

  constructor(private http: HttpClient) {
  }

  // business logic

  public createUser(user: User): Observable<User> {
    return this.http.post<User>(this.serviceUrl + '/CreateUser', user);
  }

  public createUserLogin(user: User): Observable<User> {
    return this.http.post<User>(this.serviceUrl + '/CreateUserLogin', user);
  }

  public deleteUser(id: number): Observable<User> {
    return this.http.delete<User>(this.serviceUrl + '/DeleteUser/' + id.toString());
  }

  public loadEventFlags(): Observable<EventFlag[]> {
    return this.http.get<EventFlag[]>(this.serviceUrl + '/LoadEventFlags');
  }

  public loadPermissionFlags(): Observable<PermissionFlag[]> {
    return this.http.get<PermissionFlag[]>(this.serviceUrl + '/LoadPermissionFlags');
  }

  public loadRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.serviceUrl + '/LoadRoles');
  }

  public loadUser(id: number): Observable<User> {
    return this.http.get<User>(this.serviceUrl + '/LoadUser/' + id.toString());
  }

  public loadUserLookups(): Observable<User[]> {
    return this.http.get<User[]>(this.serviceUrl + '/LoadUserLookups');
  }

  public loadUserPasswordReset(passwordResetKey: string): Observable<UserPasswordReset> {
    return this.http.get<UserPasswordReset>(this.serviceUrl + '/LoadUserPasswordReset/' + passwordResetKey);
  }

  public login(user: UserLogin): Observable<User> {
    return this.http.post<User>(this.serviceUrl + '/Login/', user);
  }

  public modifyUser(user: User): Observable<User> {
    return this.http.put<User>(this.serviceUrl + '/ModifyUser', user);
  }

  public modifyUserPassword(user: User): Observable<User> {
    return this.http.put<User>(this.serviceUrl + '/ModifyUserPassword', user);
  }

  public modifyUserPasswordReset(userPasswordReset: UserPasswordReset): Observable<UserPasswordReset> {
    return this.http.put<UserPasswordReset>(this.serviceUrl + '/ModifyUserPasswordReset', userPasswordReset);
  }

  public replaceRememberedEmailAddress(emailAddress: string) {
    if (localStorage.getItem(this.keyEmailAddress) != null) {
      this.setRememberedEmailAddress(emailAddress);
    }
  }

  public returnNewUser(): User {
    var user: User = new User();
    user.archived = false;
    user.changePassword = true;
    user.dateCreated = new Date();
    user.emailAddress = null;
    user.eventFlags = 0;
    user.firstName = null;
    user.id = 0;
    user.lastName = null;
    user.password = null;
    user.passwordConfirm = null;
    user.permissionFlags = 0;
    user.roles = [];

    return user;
  }

  public returnNewUserLogin(): UserLogin {
    var userLogin: UserLogin = new UserLogin();
    userLogin.emailAddress = null;
    userLogin.password = null;
    userLogin.rememberMe = false;
    if (localStorage.getItem(this.keyEmailAddress) != undefined) {
      userLogin.emailAddress = localStorage.getItem(this.keyEmailAddress);
      userLogin.rememberMe = true;
    }

    return userLogin;
  }

  public returnNewUserPasswordReset(): UserPasswordReset {
    var userPasswordReset: UserPasswordReset = new UserPasswordReset();
    userPasswordReset.emailAddress = "";
    userPasswordReset.password = "";
    userPasswordReset.passwordResetKey = "";
    userPasswordReset.recaptchaResponse = "";

    return userPasswordReset;
  }

  public searchUsers(userSearch: UserSearch): Observable<User[]> {
    return this.http.post<User[]>(this.serviceUrl + '/LoadUsers', userSearch);
  }

  public sendUserForgotPassword(user: UserPasswordReset): Observable<User> {
    return this.http.post<User>(this.serviceUrl + '/SendUserForgotPassword/', user);
  }

  public setRememberedEmailAddress(emailAddress: string) {
    if (emailAddress == null) {
      localStorage.removeItem(this.keyEmailAddress);
    }
    else {
      localStorage.setItem(this.keyEmailAddress, emailAddress);
    }
  }
}
