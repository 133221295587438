import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { DialogInvalidUserComponent } from './dialog-invalid-user/dialog-invalid-user.component';
import { DialogComponent } from './dialog/dialog.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [
    DialogComponent,
    DialogInvalidUserComponent,
    ForgotPasswordComponent,
    LoginComponent,
    NavMenuComponent,
    ResetPasswordComponent,
  ],
  entryComponents: [
    DialogComponent,
    DialogInvalidUserComponent,
  ],
  exports: [
    DialogComponent,
    DialogInvalidUserComponent,
    ForgotPasswordComponent,
    LoginComponent,
    NavMenuComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxCaptchaModule,
    RouterModule,
  ],
})
export class AppCommonModule {}
