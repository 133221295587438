import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class ErrorService {
  // constructors

  constructor() {
  }

  // business logic

  public getError(form, controlName, typeName, formGroupName) {
    if ((controlName != null) && (formGroupName == undefined)) {
      if (form.get(controlName).errors != null) {
        return form.get(controlName).errors[typeName];
      }
    }
    else if (controlName != null) {
      var formGroup = form.get(formGroupName) as FormGroup;
      if (controlName == null) {
        if (formGroup.errors != null) {
          return formGroup.errors[typeName];
        }
      }
      else {
        if (formGroup.get(controlName).errors != null) {
          return formGroup.get(controlName).errors[typeName];
        }
      }
    }
    else {
      if ((form.errors != undefined) && (formGroupName == undefined)) {
        return form.errors[typeName];
      }
      else {
        var formGroup = form.get(formGroupName) as FormGroup;
        if (controlName == null) {
          if (formGroup.errors != null) {
            return formGroup.errors[typeName];
          }
        }
      }
    }

    return null;
  }
}
