import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportRequest, Song } from '../models';

@Injectable()
export class ReportService {
  // variables

  private serviceUrl: string = 'api/Reports';

  // constructors

  constructor(private http: HttpClient) {
  }

  // business logic

  public loadReportSongDetails(reportRequest: ReportRequest): Observable<Song[]> {
    return this.http.post<Song[]>(this.serviceUrl + '/LoadReportSongDetails', reportRequest);
  }

  public loadReportSongSummary(reportRequest: ReportRequest): Observable<Song[]> {
    return this.http.post<Song[]>(this.serviceUrl + '/LoadReportSongSummary', reportRequest);
  }
}
