import { Component, Input, SimpleChanges } from '@angular/core';
import { TokenService } from '../../shared';
import { MonthlySchedule, MonthlyScheduleUser, MonthlyScheduleUserEvent } from '../../shared/models';

@Component({
  selector: 'app-compact-schedule',
  templateUrl: './compact-schedule.component.html'
})
export class CompactScheduleComponent {
  // variables

  public events: MonthlyScheduleUserEvent[];

  // properties

  @Input() monthlySchedules: MonthlySchedule[];

  // constructor

  constructor(private tokenService: TokenService) {
  }

  // event handlers

  ngOnChanges(changes: SimpleChanges) {
    this.updateSchedule();
  }

  // business logic

  public returnInstrument(userEvent: MonthlyScheduleUserEvent): string {
    var instrument: string = '<span>' + userEvent.instrumentName.toLowerCase() + '</span>';

    if (userEvent.isLeader) {
      instrument += '<i class=\'material-icons\'>grade</i>';
    }

    return instrument;
  }

  private updateSchedule() {
    if (this.monthlySchedules == undefined) {
      this.events = null;

      return;
    }

    var events: MonthlyScheduleUserEvent[] = [];

    for (var i: number = 0; i < this.monthlySchedules.length; i++) {
      var monthlySchedule: MonthlySchedule = this.monthlySchedules[i];
      var user: MonthlyScheduleUser = monthlySchedule.users.find(e => e.id == this.tokenService.user.id);

      if (user != undefined) {
        for (var i: number = 0; i < user.events.length; i++) {
          var event = user.events[i];
          event.dateEvent = new Date(monthlySchedule.events.find(e => e.id == event.eventId).dateEvent);
          events.push(event);
        }
      }
    }

    this.events = null;

    if (events.length > 0) {
      events = events.sort((e1, e2) => e1.dateEvent.getTime() - e2.dateEvent.getTime());

      this.events = events;
    }
  }
}
