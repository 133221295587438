import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { TokenService } from '../services';
import { PermissionFlag } from '../models';

@Injectable()
export class UserAuthGuard implements CanActivate {
  // constructors

  constructor(private router: Router, private tokenService: TokenService) { }

  // business logic

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var urlParts: UrlSegment[] = route.url;

    if (!this.tokenService.isTokenExpired()) {
      var redirect: boolean = false;

      if (urlParts.length > 0) {
        if ((urlParts[0].path == 'schedules') && ((this.tokenService.user.permissionFlags & PermissionFlag.SCHEDULES) != PermissionFlag.SCHEDULES)) {
          redirect = true;
        }
        else if ((urlParts[0].path == 'users') && ((this.tokenService.user.permissionFlags & PermissionFlag.USERS) != PermissionFlag.USERS)) {
          redirect = true;
        }
      }

      if (redirect) {
        this.router.navigate(['/']);

        return false;
      }

      return true;
    }

    this.router.navigate(['/login']);

    return false;
  }
}
