import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventFlag } from '../models/event-flag.model';
import { Instrument } from '../models/instrument.model';
import { MonthlySchedule } from '../models/monthly-schedule.model';
import { Role } from '../models/role.model';
import { ScheduleEmail } from '../models/schedule-email.model';
import { Schedule } from '../models/schedule.model';
import { User } from '../models/user.model';

@Injectable()
export class ScheduleService {
  // variables

  private serviceUrl: string = 'api/Schedules';

  // constructors

  constructor(private http: HttpClient) {
  }

  // business logic

  public loadEventFlags(): Observable<EventFlag[]> {
    return this.http.get<EventFlag[]>(this.serviceUrl + '/LoadEventFlags');
  }

  public loadInstruments(): Observable<Instrument[]> {
    return this.http.get<Instrument[]>(this.serviceUrl + '/LoadInstruments');
  }

  public loadMonthlySchedules(year: number, month: number): Observable<MonthlySchedule[]> {
    return this.http.get<MonthlySchedule[]>(this.serviceUrl + '/LoadMonthlySchedules/' + year.toString() + '/' + month.toString());
  }

  public loadRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.serviceUrl + '/LoadRoles');
  }

  public loadSchedule(roleId: number, year: number, month: number): Observable<Schedule> {
    return this.http.get<Schedule>(this.serviceUrl + '/LoadSchedule/' + roleId.toString() + '/' + year.toString() + '/' + month.toString());
  }

  public loadScheduleDates(): Observable<Date[]> {
    return this.http.get<Date[]>(this.serviceUrl + '/LoadScheduleDates');
  }

  public loadSchedules(roleId: number): Observable<Schedule[]> {
    return this.http.get<Schedule[]>(this.serviceUrl + '/LoadSchedules/' + roleId);
  }

  public loadScheduleEmail(roleId: number, year: number, month: number): Observable<ScheduleEmail> {
    return this.http.get<ScheduleEmail>(this.serviceUrl + '/LoadScheduleEmail/' + roleId.toString() + '/' + year.toString() + '/' + month.toString());
  }

  public loadUpcomingSchedule(): Observable<Event[]> {
    return this.http.get<Event[]>(this.serviceUrl + '/LoadUpcomingSchedule');
  }

  public loadUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.serviceUrl + '/LoadUsers');
  }

  public loadUserScheduleRoles(id: number): Observable<Role[]> {
    return this.http.get<Role[]>(this.serviceUrl + '/LoadUserScheduleRoles/' + id.toString());
  }

  public modifySchedule(schedule: Schedule): Observable<Schedule> {
    return this.http.put<Schedule>(this.serviceUrl + '/ModifySchedule', schedule);
  }

  public modifySchedulePublishStatus(schedule: Schedule): Observable<Schedule> {
    return this.http.put<Schedule>(this.serviceUrl + '/ModifySchedulePublishStatus', schedule);
  }

  public sendScheduleEmail(scheduleEmail: ScheduleEmail) {
    return this.http.post<ScheduleEmail>(this.serviceUrl + '/SendScheduleEmail', scheduleEmail);
  }
}
