import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, ProcessManagerService, ScheduleService } from 'src/app/shared/services';
import { Event, Instrument, Role, Schedule, User } from '../../shared/models';

@Component({
  selector: 'app-manage-schedule',
  templateUrl: './manage-schedule.component.html'
})
export class ManageScheduleComponent {
  // variables

  private dateSelected: Date;
  public displayEvents: number = -1;
  public eventFlags: number = 0;
  public filteredInstruments: Instrument[];
  public filteredUsers: User[];
  public instruments: Instrument[];
  public isValid: boolean = true;
  public pageMessage: string = '';
  public pageTitle: string = '';
  public roleId: number = 0;
  private routing: any;
  public schedule: Schedule;
  private users: User[];

  // constructor

  constructor(private datePipe: DatePipe, private dialogService: DialogService, private processManagerService: ProcessManagerService, private route: ActivatedRoute, private router: Router,
    private scheduleService: ScheduleService, public snackBar: MatSnackBar) {
  }

  // event handlers

  ngOnInit() {
    this.processManagerService.addProcesses(['instruments', 'schedule', 'users']);

    this.loadUsers();
    this.loadInstruments();
    this.routing = this.route.params.subscribe(params => {
      if ((params['role'] != undefined) && (params['year'] != undefined) && (params['month'] != undefined)) {
        var role: string = params['role'];
        var year: number = parseInt(params['year']);
        var month: number = parseInt(params['month']);

        this.roleId = Role.MUSICIAN;
        if (role == 'media') {
          this.roleId = Role.MEDIA;
        }
        else if (role == 'sound') {
          this.roleId = Role.SOUND;
        }

        this.filterInstruments();
        this.dateSelected = new Date(year, month - 1, 1);
        this.loadSchedule(this.roleId, year, month);
      }
      else {
        this.router.navigate(['/schedules']);
      }
    });
  }

  // business logic

  public changeEventFlags($event) {
    this.filterUsers();
  }

  public changeRoles($event) {
    this.filterInstruments();
    this.filterUsers();
  }

  public clickCancel() {
    this.router.navigate(['/schedules']);
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  private filterInstruments() {
    if (this.instruments == undefined) {
      return;
    }

    this.filteredInstruments = this.instruments.filter(i => (this.roleId == 0) || ((this.roleId != 0) && (i.role.id == this.roleId)));
  }

  private filterUsers() {
    if (this.users == undefined) {
      return;
    }

    this.filteredUsers = this.users.filter(u => ((this.eventFlags == 0) || ((this.eventFlags != 0) && ((u.eventFlags && this.eventFlags) == this.eventFlags))) &&
      ((this.roleId == 0) || ((this.roleId != 0) && (u.roles.find(r => r.id == this.roleId)))));
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  private loadInstruments() {
    this.scheduleService.loadInstruments().subscribe(result => {
      this.processManagerService.notify('instruments');
      this.instruments = result;
      this.filterInstruments();
    }, error => {
      this.processManagerService.notify('instruments');
      this.displayError(error.message);
    });
  }

  private loadSchedule(roleId: number, year: number, month: number) {
    this.scheduleService.loadSchedule(roleId, year, month).subscribe(result => {
      this.processManagerService.notify('schedule');
      this.schedule = result;
      this.isValid = true;

      this.pageTitle = 'Edit Schedule';
      this.pageMessage = 'To edit the schedule for <strong>' + this.datePipe.transform(this.dateSelected, 'MMMM yyyy') + '</strong>, update the information below and click the <strong>Save Schedule</strong> button.';
    }, error => {
        this.processManagerService.notify('schedule');
      this.displayError(error.message);
    });
  }

  private loadUsers() {
    this.scheduleService.loadUsers().subscribe(result => {
      this.processManagerService.notify('users');
      this.users = result;
      this.filterUsers();
    }, error => {
        this.processManagerService.notify('users');
      this.displayError(error.message);
    });
  }

  public returnEvents5CssClass(): boolean {
    if (this.schedule == undefined) {
      return;
    }

    return (this.schedule.events.length > 8)
  }

  public returnSaturdayEvents(): Event[] {
    if (this.schedule == undefined) {
      return;
    }

    var events: Event[] = this.returnDayEvents(6);

    if ((this.schedule.events.length == 9) && (new Date(this.schedule.events[0].dateEvent).getDay() == 0)) {
      var event: Event = new Event();
      events.splice(0, 0, event);
    }

    return events;
  }

  public returnSundayEvents(): Event[] {
    if (this.schedule == undefined) {
      return;
    }

    return this.returnDayEvents(0);
  }

  private returnDayEvents(type: number) {
    var events: Event[] = this.schedule.events.filter(e => (new Date(e.dateEvent)).getDay() == type);

    return events;
  }

  public returnUserName(user: User): string {
    if (user.isGuest) {
      return 'Guest';
    }

    return user.firstName + ' ' + user.lastName.substring(0, 1) + '.';
  }

  public submitSchedule(exit: boolean) {
    this.scheduleService.modifySchedule(this.schedule).subscribe(result => {
      this.schedule.id = result.id;
      this.loadSchedule(this.roleId, this.schedule.year, this.schedule.month);

      this.snackBar.open('The schedule for ' + this.datePipe.transform(this.dateSelected, 'MMMM yyyy') + ' has been edited.', '', {
        duration: 2000,
      });
      if (exit) {
        this.router.navigate(['/schedules']);
      }
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  public userAdded() {
    this.isValid = false;
  }

  public userInstrumentAdded() {
    var user = this.schedule.events.find((e) => {
      return e.users.some((u) => u.instrument == null);
    });

    if (user != null) {
      this.isValid = false;
    }
    else {
      this.isValid = true;
    }
  }
}
