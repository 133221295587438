import { DatePipe } from '@angular/common';
import { Component, ElementRef, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatSnackBar, MatAutocompleteSelectedEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Event, Song, User, DialogData, PermissionFlag } from 'src/app/shared/models';
import { DialogService, ErrorService, EventService, ProcessManagerService, SongService, TokenService } from 'src/app/shared/services';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-manage-event',
  templateUrl: './manage-event.component.html'
})
export class ManageEventComponent {
  // variables

  private currentEditIndex: number = -1;
  public event: Event;
  public eventForm: FormGroup;
  public isAdministrator: boolean = false;
  public isViewing: boolean = true;
  private originalSong: Song;
  public pageMessage: string = '';
  public pageTitle: string = '';
  private routing: any;
  public songs: Song[];
  public filteredSongs: Observable<Song[]>;

  // properties

  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('song', { static: false }) song: ElementRef;
  @ViewChild('fileInputChart', { static: false }) fileInputChart: ElementRef;
  public get songsForm(): FormArray {
    return this.eventForm.get('songs') as FormArray;
  }

  public songsCtrl = new FormControl();

  // constructor

  constructor(private datePipe: DatePipe, private dialogService: DialogService, private errorService: ErrorService, private eventService: EventService, public formBuilder: FormBuilder,
    private processManagerService: ProcessManagerService, private route: ActivatedRoute, private router: Router, public snackBar: MatSnackBar, private songService: SongService, private tokenService: TokenService) {
    this.isAdministrator = this.tokenService.canAccessArea(PermissionFlag.EVENTS);
  }

  // event handlers

  ngOnInit() {
    this.processManagerService.addProcesses(['event', 'songs']);

    this.isViewing = false;
    if (this.router.url.indexOf('/view/') != -1) {
      this.isViewing = true;
    }

    this.routing = this.route.params.subscribe(params => {
      if (params['id'] != undefined) {
        var id: number = parseInt(params['id']);
        this.loadEvent(id);
      }
    });
  }

  // business logic

  public addNewSong() {
    var song: Song = this.songService.returnNewSong();
    this.addSong(song);
    var index: number = this.songsForm.controls.length;
    this.clickEditSong(index - 1);
  }

  private addSong(song: Song) {
    this.songsForm.push(this.formBuilder.group({
      authors: [song.authors, []],
      id: [song.id, [Validators.required]],
      key: [song.key, [Validators.required]],
      title: [song.title, [Validators.required]],
      isEditing: [false, []],
    }));
  }

  public cdkDropListDroppedSong(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.songsForm.controls, event.previousIndex, event.currentIndex);
  }

  public clickCancel() {
    this.router.navigate(['/events']);
  }

  public clickCancelSong(index: number) {
    if (this.originalSong != null) {
      this.songsForm.controls[index].get('authors').setValue(this.originalSong.authors);
      this.songsForm.controls[index].get('id').setValue(this.originalSong.id);
      this.songsForm.controls[index].get('key').setValue(this.originalSong.key);
      this.songsForm.controls[index].get('title').setValue(this.originalSong.title);
      this.originalSong = null;
    }

    if (this.songsForm.controls[index].get('id').value != 0) {
      this.songsForm.controls[index].get('isEditing').setValue(false);
    }
    else {
      this.songsForm.removeAt(index);
    }

    this.songsCtrl.setValue(null);

    this.currentEditIndex = -1;
  }

  public clickDeleteSong(index: number) {
    var title = this.songsForm.controls[index].get('title').value;
    var dialogData: DialogData = new DialogData(true, '', this.pageTitle);
    dialogData.text = 'Are you sure you want to delete the song "' + title + '"?';

    this.dialogService.openDialog(dialogData).subscribe(result => {
      if (result.response) {
        this.songsForm.removeAt(index);
      }
    });

  }

  public clickEditEvent() {
    this.router.navigate(['/events/edit', this.event.id]);
  }

  public clickEditSong(index: number) {
    this.originalSong = new Song();
    this.originalSong.authors = this.songsForm.controls[index].get('authors').value;
    this.originalSong.id = this.songsForm.controls[index].get('id').value;
    this.originalSong.key = this.songsForm.controls[index].get('key').value;
    this.originalSong.title = this.songsForm.controls[index].get('title').value;

    this.songsCtrl.setValue(this.originalSong.title);
    this.songsForm.controls[index].get('isEditing').setValue(true);

    this.currentEditIndex = index;
    setTimeout(() => {
      this.song.nativeElement.focus();
    })
  }

  public clickUpdateSong(index: number) {
    if ((this.songsForm.controls[index].get('id').value == 0) || (this.songsForm.controls[index].get('key').value == null)) {
      this.displayError('Please choose a song and enter a key before updating.');
      return;
    }

    var songId: number = this.songsForm.controls[index].get('id').value;
    var song: Song = this.songs.find(s => s.id == songId);

    this.songsForm.controls[index].get('authors').setValue(song.authors);
    this.songsForm.controls[index].get('title').setValue(song.title);

    this.originalSong = null;

    this.songsCtrl.setValue(null);
    this.songsForm.controls[index].get('isEditing').setValue(false);

    this.currentEditIndex = -1;
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  private filterSongs(value: string): Song[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.songs.filter(s => s.title.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  public getError(controlName, typeName, formGroupName) {
    return this.errorService.getError(this.eventForm, controlName, typeName, formGroupName);
  }

  private initializeForm() {
    this.eventForm = this.formBuilder.group({
      songs: this.formBuilder.array([]),
    });
  }

  public isCurrentlyEditing() {
    if (this.isViewing) {
      return true;
    }

    return (this.currentEditIndex != -1);
  }

  public isDisabled(index: number): boolean {
    if (this.isViewing) {
      return true;
    }

    if ((this.currentEditIndex != -1) && (this.currentEditIndex != index)) {
      return true;
    }

    return false;
  }

  public isEditing(index: number) {
    if (this.songsForm.controls[index].get('isEditing').value) {
      return true;
    }
    return false;
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  public keypressCancelSong($event, index: number) {
    if (($event.which == 13) || ($event.which == 32)) {
      this.clickCancelSong(index)
    }
  }

  public keypressUpdateSong($event, index: number) {
    if (($event.which == 13) || ($event.which == 32)) {
      this.clickUpdateSong(index)
    }
  }

  private loadEvent(id: number) {
    this.eventService.loadEvent(id).subscribe(result => {
      this.event = result;
      this.initializeForm();

      for (var i: number = 0; i < this.event.songs.length; i++) {
        this.addSong(this.event.songs[i]);
      }

      this.loadSongs();

      this.processManagerService.notify('event');

      if (this.isViewing) {
        this.pageTitle = 'View Event';
        this.pageMessage = 'These are the details associated with this event.';
      }
      else {
        this.pageTitle = 'Edit Event';
        this.pageMessage = 'To edit this event, update the information below and click the <strong>Save Event</strong> button.';
      }
    }, error => {
      this.processManagerService.notify('event');
      this.displayError(error.message);
    });
  }

  private loadSongs() {
    this.songService.loadSongs().subscribe(result => {
      this.songs = result;
      this.processManagerService.notify('songs');

      this.filteredSongs = this.songsCtrl.valueChanges.pipe(
        startWith(null),
        map((title: string | null) => title ? this.filterSongs(title) : this.songs.slice())
      );
    }, error => {
      this.processManagerService.notify('songs');
      this.displayError(error.message);
    });
  }

  public optionSelectedSong(event: MatAutocompleteSelectedEvent) {
    if (this.songs != undefined) {
      var song: Song = this.songs.find(s => s.id == event.option.value);
      this.songsForm.controls[this.currentEditIndex].get('id').setValue(event.option.value);
      this.songsCtrl.setValue(song.title);
    }
  }

  public returnAuthors(song: Song) {
    if (song.authors != null) {
      return '[' + song.authors + ']';
    }

    return '';
  }

  public returnLeaders(users: User[]): string {
    var items: string[] = [];

    for (var i: number = 0; i < users.length; i++) {
      items.push(users[i].name);
    }

    return items.join(', ');
  }

  public submitEvent() {
    this.updateEvent();

    this.processManagerService.force();

    this.eventService.modifyEvent(this.event).subscribe(result => {
      this.processManagerService.unforce();
      this.snackBar.open('The event for ' + this.datePipe.transform(this.event.dateEvent, 'MMMM d, yyyy') + ' has been updated.', '', {
        duration: 2000,
      });
      this.router.navigate(['/events']);
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  private updateEvent() {
    this.event.songs = [];
    for (var i: number = 0; i < this.songsForm.controls.length; i++) {
      var song: Song = this.songService.returnNewSong();
      song.id = this.songsForm.controls[i].get('id').value;
      song.key = this.songsForm.controls[i].get('key').value;
      this.event.songs.push(song);
    }
  }
}
