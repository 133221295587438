export class Role {
  // constants

  public static MUSICIAN: number = 1;
  public static MEDIA: number = 2;
  public static SOUND: number = 3;

  // variables

  id: number;
  name: string;
}
