import { Component, Input, SimpleChanges } from '@angular/core';
import { MonthlySchedule, MonthlyScheduleUser, MonthlyScheduleUserEvent, Role } from '../../shared/models';

@Component({
  selector: 'app-monthly-schedule',
  templateUrl: './monthly-schedule.component.html'
})
export class MonthlyScheduleComponent {
  // variables

  public schedules: MonthlySchedule[];

  // properties

  @Input() monthlySchedules: MonthlySchedule[];
  @Input() day: number;

  // constructor

  constructor() {
  }

  // event handlers

  ngOnChanges(changes: SimpleChanges) {
    this.updateSchedule();
  }

  // business logic

  public returnInstrument(user: MonthlyScheduleUser, eventId: number): string {
    var event: MonthlyScheduleUserEvent = user.events.find(e => e.eventId == eventId);

    if (event != undefined) {
      var instrument: string = '<span>' + event.instrumentName.toLowerCase() + '</span>';

      if (event.isLeader) {
        instrument += '<i class=\'material-icons\'>grade</i>';
      }

      return instrument;
    }

    return '-'
  }

  public returnRoleName(role: Role): string {
    if (role.id == Role.MUSICIAN) {
      return role.name + 's';
    }

    return role.name;
  }

  private updateSchedule() {
    if (this.monthlySchedules == undefined) {
      this.schedules = null;

      return;
    }

    this.schedules = [];

    for (var i: number = 0; i < this.monthlySchedules.length; i++) {
      let monthlySchedule: MonthlySchedule = this.monthlySchedules[i];
      var schedule: MonthlySchedule = new MonthlySchedule();
      schedule.events = monthlySchedule.events.filter(e => (new Date(e.dateEvent)).getDay() == this.day);
      let eventIds: number[] = schedule.events.map(e => e.id);
      schedule.users = monthlySchedule.users.filter(u => u.events.some(e => eventIds.indexOf(e.eventId) != -1));
      schedule.role = monthlySchedule.role;
      this.schedules.push(schedule);
    }
  }
}
