import { Role } from "./role.model";

export class Instrument {
  // constants

  public static ACOUSTIC_GUITAR: number = 1;
  public static ELECTRIC_GUITAR: number = 2;
  public static VOCALS: number = 3;
  public static BASS: number = 4;
  public static KEYBOARD: number = 5;
  public static DRUMS: number = 6;
  public static PERCUSSION: number = 7;
  public static BANJO: number = 8;
  public static FLUTE: number = 9;
  public static SAXOPHONE: number = 10;
  public static MANDOLIN: number = 11;
  public static TRUMPET: number = 12;
  public static FIDDLE: number = 13;
  public static SYNTHESIZER: number = 14;
  public static MEDIA: number = 15;
  public static SOUND: number = 16;

  // variables

  cssClass: string;
  flags: number;
  id: number;
  name: string;
  role: Role;
}
