import { Song } from "./song.model";
import { User } from "./user.model";

export class Event {
  // variables

  id: number;
  dateEvent: Date;
  key: string;
  songs: Song[];
  users: User[];
}
