import { Role } from './role.model';

export class UserSearch {
  // constants

  public static ALL: number = -1;
  public static ARCHIVED: number = 0;
  public static ACTIVE: number = 1;

  // variables

  role: Role;
  searchTerm: string;
  userStatus: number = UserSearch.ACTIVE;

  // business logic

  public isEmpty(): boolean {
    if (((this.searchTerm == null) || (this.searchTerm == '')) && ((this.role == null) || (this.role.id == 0)) && (this.userStatus == UserSearch.ALL)) {
      return true;
    }

    return false;
  }
}
