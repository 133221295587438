import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListEventsComponent } from './admin/list-events/list-events.component';
import { ListSchedulesComponent } from './admin/list-schedules/list-schedules.component';
import { ListSongsComponent } from './admin/list-songs/list-songs.component';
import { ListUsersComponent } from './admin/list-users/list-users.component';
import { ManageEventComponent } from './admin/manage-event/manage-event.component';
import { ManageScheduleComponent } from './admin/manage-schedule/manage-schedule.component';
import { ManageSongComponent } from './admin/manage-song/manage-song.component';
import { ManageUserComponent } from './admin/manage-user/manage-user.component';
import { SendScheduleEmailComponent } from './admin/send-schedule-email/send-schedule-email.component';
import { ViewReportsComponent } from './admin/view-reports/view-reports.component';
import { ForgotPasswordComponent } from './common/forgot-password/forgot-password.component';
import { LoginComponent } from './common/login/login.component';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { UserAuthGuard } from './shared/guards/user-auth.guard';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { EditProfileComponent } from './user/edit-profile/edit-profile.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password/:emailAddress', component: ForgotPasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:passwordResetKey', component: ResetPasswordComponent },
  { path: 'edit-profile', component: EditProfileComponent, canActivate: [UserAuthGuard] },
  { path: 'events/edit/:id', component: ManageEventComponent, canActivate: [UserAuthGuard] },
  { path: 'events/view/:id', component: ManageEventComponent, canActivate: [UserAuthGuard] },
  { path: 'events/create', component: ManageEventComponent, canActivate: [UserAuthGuard] },
  { path: 'events', component: ListEventsComponent, canActivate: [UserAuthGuard] },
  { path: 'schedules/edit/:role/:year/:month', component: ManageScheduleComponent, canActivate: [UserAuthGuard] },
  { path: 'schedules/send-email/:role/:year/:month', component: SendScheduleEmailComponent, canActivate: [UserAuthGuard] },
  { path: 'schedules', component: ListSchedulesComponent, canActivate: [UserAuthGuard] },
  { path: 'songs/edit/:id', component: ManageSongComponent, canActivate: [UserAuthGuard] },
  { path: 'songs/view/:id', component: ManageSongComponent, canActivate: [UserAuthGuard] },
  { path: 'songs/create', component: ManageSongComponent, canActivate: [UserAuthGuard] },
  { path: 'songs', component: ListSongsComponent, canActivate: [UserAuthGuard] },
  { path: 'users/edit/:id', component: ManageUserComponent, canActivate: [UserAuthGuard] },
  { path: 'users/create', component: ManageUserComponent, canActivate: [UserAuthGuard] },
  { path: 'users', component: ListUsersComponent, canActivate: [UserAuthGuard] },
  { path: 'reports', component: ViewReportsComponent, canActivate: [UserAuthGuard] },
  { path: '', component: DashboardComponent, pathMatch: 'full', canActivate: [UserAuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
