export class MonthlyScheduleUserEvent {
  // variables

  cssClass: string;
  dateEvent: Date;
  eventId: number;
  instrumentId: number;
  instrumentName: string;
  isLeader: boolean;
}
