import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../models/user.model';
import { UserService } from './user.service';

@Injectable()
export class TokenService {
  // variables

  public static KEY_EMAIL_ADDRESS: string = 'emailAddress';
  public static KEY_FIRST_NAME: string = 'firstName';
  public static KEY_LAST_NAME: string = 'lastName';
  public static KEY_ROLE: string = 'role';
  public static KEY_USER_ID: string = 'id';
  public static TOKEN_NAME: string = 'token';
  private tokenUser: User = null;

  // properties

  get user(): User {
    if (this.tokenUser == null) {
      this.populateUser();
    }

    return this.tokenUser;
  }

  // constructors

  constructor(private jwtHelperService: JwtHelperService, private userService: UserService) {
    if (this.tokenUser == null) {
      this.populateUser();
    }
  }

  // business logic

  public canAccessArea(permissionFlag: number): boolean {
    if ((this.tokenUser == undefined) || (this.tokenUser.permissionFlags == undefined)) {
      return false;
    }

    if ((this.tokenUser.permissionFlags & permissionFlag) != permissionFlag) {
      return false;
    }

    return true;
  }

  private getDecodedToken(): any {
    var token: string = this.getToken();
    var decodedToken: any = this.jwtHelperService.decodeToken(token);

    return decodedToken;
  }

  private getToken(): string {
    var token: string = localStorage.getItem(TokenService.TOKEN_NAME);

    return token;
  }

  private getValue(key): any {
    var decodedToken: any = this.getDecodedToken();
    var keyEmailAddress: string = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';
    var keyFirstName: string = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname';
    var keyLastName: string = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname';
    var keyRole: string = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
    var keyUserId: string = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier';
    var value: any = null;

    if (decodedToken != null) {
      var keyName: string = '';

      switch (key) {
        case TokenService.KEY_EMAIL_ADDRESS:
          keyName = keyEmailAddress;
          break;
        case TokenService.KEY_FIRST_NAME:
          keyName = keyFirstName;
          break;
        case TokenService.KEY_LAST_NAME:
          keyName = keyLastName;
          break;
        case TokenService.KEY_ROLE:
          keyName = keyRole;
          break;
        case TokenService.KEY_USER_ID:
          keyName = keyUserId;
          break;
      }

      if (decodedToken[keyName] != undefined) {
        value = decodedToken[keyName];
      }
    }

    return value;
  }

  public isAdminUser(): boolean {
    if (this.user == null) {
      return false;
    }

    return false;
  }

  public isTokenExpired(): boolean {
    var token: string = this.getToken();

    if (token) {
      return this.jwtHelperService.isTokenExpired(token);
    }

    return true;
  }

  private populateUser() {
    if (!this.isTokenExpired()) {
      this.tokenUser = new User();
      this.tokenUser.emailAddress = this.getValue(TokenService.KEY_EMAIL_ADDRESS) as string;
      this.tokenUser.firstName = this.getValue(TokenService.KEY_FIRST_NAME) as string;
      this.tokenUser.id = this.getValue(TokenService.KEY_USER_ID) as number;
      this.tokenUser.lastName = this.getValue(TokenService.KEY_LAST_NAME) as string;
      this.tokenUser.permissionFlags = this.getValue(TokenService.KEY_ROLE) as number;

      if (sessionStorage.getItem('loggedIn') == undefined) {
        this.userService.createUserLogin(this.tokenUser).subscribe(result => {
          sessionStorage.setItem('loggedIn', "true");
        });
      }
    }
  }

  public removeToken() {
    localStorage.removeItem(TokenService.TOKEN_NAME);
    this.tokenUser = null;
  }

  public setToken(token: string) {
    localStorage.setItem(TokenService.TOKEN_NAME, token);
  }
}
