import { Component, Input } from '@angular/core';
import { DialogService, SongService } from '../../shared';
import { Song } from '../../shared/models';

@Component({
  selector: 'app-song-statistics',
  templateUrl: './song-statistics.component.html'
})
export class SongStatisticsComponent {
  // properties

  @Input() songs: Song[];
  @Input() title: string;

  // constructor

  constructor(private dialogService: DialogService, private songService: SongService) {
  }

  // business logic

  public clickViewChart(song: Song) {
    let fileName: string = this.songService.returnChartFileName(song.title);

    this.songService.loadSongChart(song.id).subscribe(result => {
      this.songService.viewChart(result, fileName);
    }, error => {
      this.displayError(error.message);
    });
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, "Song Statistics");
  }
}
