import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData, Event, PermissionFlag, Song } from 'src/app/shared/models';
import { DialogService, ErrorService, ProcessManagerService, SongService, TokenService } from 'src/app/shared/services';

@Component({
  selector: 'app-manage-song',
  templateUrl: './manage-song.component.html'
})
export class ManageSongComponent {
  // variables

  public isAdministrator: boolean = false;
  public isViewing: boolean = false;
  public pageMessage: string = '';
  public pageTitle: string = '';
  private routing: any;
  public song: Song;
  public songForm: FormGroup;

  // properties

  @ViewChild('fileInputChart', { static: false }) fileInputChart: ElementRef;

  // constructor

  constructor(private dialogService: DialogService, private errorService: ErrorService, public formBuilder: FormBuilder, private processManagerService: ProcessManagerService,
    private route: ActivatedRoute, private router: Router, public snackBar: MatSnackBar, private songService: SongService, private tokenService: TokenService) {
    this.isAdministrator = this.tokenService.canAccessArea(PermissionFlag.SONGS);
  }

  // event handlers

  ngOnInit() {
    this.processManagerService.addProcesses(['song']);

    this.isViewing = false;
    if (this.router.url.indexOf('/view/') != -1) {
      this.isViewing = true;
    }

    this.routing = this.route.params.subscribe(params => {
      if (params['id'] != undefined) {
        var id: number = parseInt(params['id']);
        this.loadSong(id);
      }
      else {
        this.createNewSong();
      }
    });
  }

  // business logic

  public changeFileUpload($event) {
    let fileList: FileList = $event.target.files;

    if (fileList.length > 0) {
      var validExtensions: string[] = ['.pdf'];
      var file: File = fileList[0];
      var extension: string = file.name.substring(file.name.lastIndexOf('.'));

      if (validExtensions.indexOf(extension.toLowerCase()) == -1) {
        this.displayError('The selected file has an invalid file type. Only PDF (.pdf) files are permitted.');
      }
      else {
        const promise: Promise<number[]> = new Promise((resolve, reject) => {
          var fileReader: FileReader = new FileReader();
          fileReader.onload = function (e) {
            var fileBuffer: ArrayBuffer = fileReader.result as ArrayBuffer;
            var bytes = new Uint8Array(fileBuffer);
            var data: number[] = [].slice.call(bytes);

            resolve(data);
          }
          fileReader.readAsArrayBuffer(file);
        });

        promise.then((data) => {
          if (data.length > 1024 * 1000) {
            this.displayError('The selected file is greater than 5 MB in size. Please try again.');
          }
          else {
            this.songForm.get('chart').setValue(data);
            this.songForm.get('hasChart').setValue(true);
          }
        })
      }
    }
  }

  public clickCancel() {
    this.router.navigate(['/songs']);
  }

  public clickDeleteChart() {
    var dialogData: DialogData = new DialogData(true, '', 'Delete Chart');
    dialogData.text = '<p>Are you sure you want to permanently delete this chart?</p>';

    this.dialogService.openDialog(dialogData).subscribe(result => {
      if (result.response) {
        this.songForm.get('chart').setValue(null);
        this.songForm.get('hasChart').setValue(false);
      }
    });
  }

  public clickEditSong() {
    this.router.navigate(['/songs/edit', this.song.id]);
  }

  public clickUploadFile() {
    if (this.songForm.get('hasChart').value) {
      var dialogData: DialogData = new DialogData(true, '', 'Upload Chart');
      dialogData.text = '<p>Are you sure you want to overwrite the existing chart?</p>';

      this.dialogService.openDialog(dialogData).subscribe(result => {
        if (result.response) {
          this.fileInputChart.nativeElement.click();
        }
      });
    }
    else {
      this.fileInputChart.nativeElement.click();
    }
  }

  public clickViewChart() {
    let chart: number[] = this.songForm.get('chart').value;
    let fileName: string = this.songService.returnChartFileName(this.songForm.get('title').value);

    if (chart != null) {
      if ((chart != null) && (chart.length != 0)) {
        var blob: Blob = this.songService.returnBlob(chart);

        this.songService.viewChart(blob, fileName);
      }
      else {
        this.songService.loadSongChart(this.song.id).subscribe(result => {
          this.songService.viewChart(result, fileName);
        }, error => {
          this.displayError(error.message);
        });
      }
    }
  }

  private createNewSong() {
    this.song = this.songService.returnNewSong();

    this.initializeForm();
    this.processManagerService.notify('song');

    this.pageTitle = 'Create Song';
    this.pageMessage = 'To create a new song, provide the information below and click the <strong>Create Song</strong> button.';
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  public getError(controlName, typeName, formGroupName) {
    return this.errorService.getError(this.songForm, controlName, typeName, formGroupName);
  }

  public hasUploadedChart(type): boolean {
    let hasChart: boolean = this.songForm.get('hasChart').value;

    if (hasChart) {
      return true;
    }

    return false;
  }

  private initializeForm() {
    this.songForm = this.formBuilder.group({
      archived: [this.song.archived, []],
      authors: [this.song.authors, []],
      chart: [this.song.chart, []],
      dateIntroduced: [this.song.dateIntroduced, []],
      hasChart: [this.song.hasChart, []],
      title: [this.song.title, [Validators.required]],
      youtubeUrl: [this.song.youtubeUrl, []],
    });
    if (this.isViewing) {
      this.songForm.get('archived').disable();
      this.songForm.get('authors').disable();
      this.songForm.get('dateIntroduced').disable();
      this.songForm.get('title').disable();
      this.songForm.get('youtubeUrl').disable();
    }
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  private loadSong(id: number) {
    this.songService.loadSong(id).subscribe(result => {
      this.song = result;
      this.initializeForm();

      this.processManagerService.notify('song');

      if (this.isViewing) {
        this.pageTitle = 'View Song';
        this.pageMessage = 'These are the details associated with the song <strong>' + this.song.title + '</strong>.';
      }
      else {
        this.pageTitle = 'Edit Song';
        this.pageMessage = 'To edit the song <strong>' + this.song.title + '</strong>, update the information below and click the <strong>Save Song</strong> button.';
      }
    }, error => {
      this.processManagerService.notify('song');
      this.displayError(error.message);
    });
  }

  public returnEventUrl(event: Event): string {
    return '/events/view/' + event.id.toString();
  }

  public submitSong() {
    this.updateSong();

    this.processManagerService.force();

    if (this.song.id == 0) {
      this.songService.createSong(this.song).subscribe(result => {
        this.processManagerService.unforce();
        this.snackBar.open('The song "' + this.song.title + '" has been created.', '', {
          duration: 2000,
        });
        this.router.navigate(['/songs']);
      }, error => {
        this.processManagerService.unforce();
        this.displayError(error.message);
      });
    }
    else {
      this.songService.modifySong(this.song).subscribe(result => {
        this.processManagerService.unforce();
        this.snackBar.open('The song "' + this.song.title + '" has been updated.', '', {
          duration: 2000,
        });
        this.router.navigate(['/songs']);
      }, error => {
        this.processManagerService.unforce();
        this.displayError(error.message);
      });
    }
  }

  private updateSong() {
    this.song.archived = this.songForm.get('archived').value;
    this.song.authors = this.songForm.get('authors').value;
    this.song.chart = this.songForm.get('chart').value;
    this.song.dateIntroduced = this.songForm.get('dateIntroduced').value;
    this.song.hasChart = this.songForm.get('hasChart').value;
    this.song.title = this.songForm.get('title').value;
    this.song.youtubeUrl = this.songForm.get('youtubeUrl').value;
  }
}
