import { AbstractControl } from '@angular/forms';

export function PasswordMatchValidator(control: AbstractControl) {
  // business logic

  var password: AbstractControl = control.get('password');
  var passwordConfirm: AbstractControl = control.get('passwordConfirm');

  if ((passwordConfirm.value != "") && (password.value !== passwordConfirm.value)) {
    passwordConfirm.markAsTouched();
    passwordConfirm.setErrors({ mismatch: true });
    return { mismatch: true };
  }

  return null;
}
