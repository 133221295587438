import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogService } from 'src/app/shared/services';
import { DialogData, Event, Instrument, Role, User } from '../../shared/models';
import { DialogGuestComponent, DialogGuestData } from '../dialog-guest/dialog-guest.component';

@Component({
  selector: 'app-schedule-event',
  templateUrl: './schedule-event.component.html'
})
export class ScheduleEventComponent {
  // properties

  @Input() events: Event[];
  @Input() instruments: Instrument[];
  @Input() roleId: number;
  @Output() userAdded: EventEmitter<unknown> = new EventEmitter();
  @Output() userInstrumentAdded: EventEmitter<unknown> = new EventEmitter();

  // constructor

  constructor(private dialog: MatDialog, private dialogService: DialogService) {
  }

  // event handlers

  ngOnInit() {
  }

  // business logic

  public clickDeleteUsers(event: Event) {
    var dialogData: DialogData = new DialogData(true, '', 'Edit Schedule');
    dialogData.text = 'Are you sure you want to remove all personnel?';

    this.dialogService.openDialog(dialogData).subscribe(result => {
      if (result.response) {
        event.users = [];
      }
    });
  }

  public clickDeleteUser(event: Event, user: User) {
    var dialogData: DialogData = new DialogData(true, '', 'Edit Schedule');
    dialogData.text = 'Are you sure you want to remove this individual?';

    this.dialogService.openDialog(dialogData).subscribe(result => {
      if (result.response) {
        var index: number = event.users.findIndex(u => u.id == user.id);
        if (index != -1) {
          event.users.splice(index, 1);
        }
      }
    });
  }

  public clickDisplayGuestMusician(user: User) {
    const dialogRef = this.dialog.open(DialogGuestComponent, {
      width: '600px',
      data: new DialogGuestData(this.roleId, user)
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        user.emailAddress = result.response.emailAddress;
        user.firstName = result.response.firstName;
        user.lastName = result.response.lastName;
      }
    });
  }

  public clickIsLeader(user: User) {
    user.isLeader = !user.isLeader;
  }

  public isInstrumentMissing(user: User) {
    if (user.instrument == null) {
      return true;
    }

    return false;
  }

  public onDropSuccessEvent($event, event: Event): any {
    if ($event.dragData.type == 'user') {
      if (event.users == undefined) {
        event.users = [];
      }

      var user: User = $event.dragData.data;

      if (user.isGuest || (event.users.find(u => u.id == user.id) == undefined)) {
        var instrument: Instrument = null;
        if ((this.instruments != undefined) && (this.instruments.length == 1)) {
          instrument = new Instrument();
          instrument.cssClass = this.instruments[0].cssClass;
          instrument.id = this.instruments[0].id;
          instrument.name = this.instruments[0].name;
        }
        var newUser: User = new User();
        newUser.emailAddress = user.emailAddress;
        newUser.firstName = user.firstName;
        newUser.id = user.id;
        newUser.isGuest = user.isGuest;
        newUser.isLeader = false;
        newUser.instrument = instrument;
        newUser.lastName = user.lastName;
        newUser.name = user.name;
        event.users.push(newUser);
        if (instrument == null) {
          this.userAdded.emit();
        }
        if (newUser.isGuest) {
          if (this.roleId == Role.MEDIA) {
            newUser.lastName = "Media";
          }
          else if (this.roleId == Role.MUSICIAN) {
            newUser.lastName = "Musician";
          }
          else if (this.roleId == Role.SOUND) {
            newUser.lastName = "Sound";
          }
        }
      }
    }
  }

  public onDropSuccessInstrument($event, user: User, event: Event) {
    if ($event.dragData.type == 'instrument') {
      var instrument: Instrument = $event.dragData.data;
      var newInstrument: Instrument = new Instrument();
      newInstrument.cssClass = instrument.cssClass;
      newInstrument.id = instrument.id;
      newInstrument.name = instrument.name;
      user.instrument = newInstrument;
      this.userInstrumentAdded.emit();
    }
    else if ($event.dragData.type == 'user') {
      this.onDropSuccessEvent($event, event);
    }
  }

  public returnDay(date: Date): string {
    if ((new Date(date)).getDay() == 0) {
      return 'Sunday';
    }

    return 'Saturday';
  }
}
