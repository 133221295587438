export class PermissionFlag {
  // constants

  public static EVENTS: number = 1;
  public static SONGS: number = 2;
  public static USERS: number = 4;
  public static SCHEDULES: number = 8;
  public static REPORTS: number = 16;

  // variables

  id: number;
  isSelected: boolean
  name: string;
}
