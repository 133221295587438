import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { Router } from '@angular/router';
import { DialogData, Role, User, UserSearch } from 'src/app/shared/models';
import { DialogService, ProcessManagerService, UserService } from 'src/app/shared/services';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html'
})
export class ListUsersComponent {
  // variables

  public displayedColumns: string[] = ['dateCreated', 'name', 'emailAddress', 'roles', 'userStatus', 'edit', 'archive'];
  public pageMessage: string = '';
  public pageTitle: string = 'Manage Users';
  private paginator: MatPaginator;
  public roles: Role[];
  private searchStorageKey: string = 'userSearch';
  private sort: MatSort;
  public users: MatTableDataSource<User> = new MatTableDataSource<User>();
  public userSearchForm: FormGroup;

  // properties

  @ViewChild(MatPaginator, { static: false }) set matPaginator(value: MatPaginator) {
    this.paginator = value;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort, { static: false }) set matSort(value: MatSort) {
    this.sort = value;
    this.setDataSourceAttributes();
  }

  // constructor

  constructor(private dialogService: DialogService, public formBuilder: FormBuilder, private processManagerService: ProcessManagerService, private router: Router, public snackBar: MatSnackBar,
    private userService: UserService) {
  }

  // event handlers

  ngOnInit() {
    this.processManagerService.addProcesses(['users', 'roles']);

    this.loadRoles();
    this.initializeSearchForm();
    let userSearch: UserSearch = this.loadUserSearch();
    if (userSearch == null) {
      this.loadUsers(new UserSearch());
    }
    else {
      this.loadUsers(userSearch);
    }
  }

  // business logic

  public clickArchiveUser(user: User) {
    var dialogData: DialogData = new DialogData(true, '', this.pageTitle);
    dialogData.text = 'Are you sure you want to archive the user "' + user.name + '"?';

    this.dialogService.openDialog(dialogData).subscribe(result => {
      if (result.response) {
        this.userService.deleteUser(user.id).subscribe(result => {
          this.snackBar.open('The user "' + user.name + '" was successfully archived.', '', {
            duration: 2000,
          });

          let userSearch: UserSearch = this.loadUserSearch();
          if (userSearch == null) {
            this.loadUsers(new UserSearch());
          }
          else {
            this.loadUsers(this.loadUserSearch());
          }
        }, error => {
          this.displayError(error.message);
        });
      }
    });
  }

  public clickCreateNewUser() {
    this.router.navigate(['/users/create']);
  }

  public clickResetSearch() {
    this.initializeSearchForm();
    this.loadUsers(new UserSearch());
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  private initializeSearchForm() {
    this.userSearchForm = this.formBuilder.group({
      roleId: [0, []],
      searchTerm: [null, []],
      userStatus: [UserSearch.ACTIVE, []],
    });
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  private loadRoles() {
    this.userService.loadRoles().subscribe(result => {
      this.processManagerService.notify('roles');
      this.roles = result;
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  private loadUsers(userSearch: UserSearch) {
    this.processManagerService.resetProcess('users');
    this.userService.searchUsers(userSearch).subscribe(result => {
      this.processManagerService.notify('users');
      this.loadUserResults(result, userSearch);
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  private loadUserSearch(): UserSearch {
    var userSearch: UserSearch = JSON.parse(sessionStorage.getItem(this.searchStorageKey));

    if (userSearch != null) {
      if (userSearch.role != null) {
        this.userSearchForm.get('roleId').setValue(userSearch.role.id);
      }
      if (userSearch.searchTerm != null) {
        this.userSearchForm.get('searchTerm').setValue(userSearch.searchTerm);
      }
      if (userSearch.userStatus != null) {
        this.userSearchForm.get('userStatus').setValue(userSearch.userStatus);
      }
    }

    return userSearch;
  }

  private loadUserResults(result: User[], userSearch: UserSearch) {
    this.users = new MatTableDataSource<User>(result);

    this.pageMessage = 'These are the users in the database.';
    if (this.users.data.length == 0) {
      if ((userSearch == null) || userSearch.isEmpty()) {
        this.pageMessage = 'There are no users in the database.';
      }
      else {
        this.pageMessage = 'There are no users in the database that match your search criteria.';
      }
    }
  }

  public returnUserRoles(user: User): string {
    var roles: string[] = [];
    for (var i: number = 0; i < user.roles.length; i++) {
      roles.push(user.roles[i].name);
    }

    return roles.join(', ');
  }

  private setDataSourceAttributes() {
    setTimeout(() => {
      this.users.paginator = this.paginator;
      this.users.sort = this.sort;
      if (this.sort != undefined) {
        this.sort.sortChange.subscribe((sort: Sort) => {
          this.users.paginator.firstPage();
        });
      }
    });
  }

  public submitUserSearch() {
    var userSearch: UserSearch = new UserSearch();
    userSearch.role = new Role();
    userSearch.role.id = 0;
    if (this.userSearchForm.get('roleId').value != null) {
      userSearch.role.id = this.userSearchForm.get('roleId').value;
    }
    userSearch.searchTerm = this.userSearchForm.get('searchTerm').value;
    userSearch.userStatus = UserSearch.ALL;
    if (this.userSearchForm.get('userStatus').value != null) {
      userSearch.userStatus = this.userSearchForm.get('userStatus').value;
    }

    sessionStorage.setItem(this.searchStorageKey, JSON.stringify(userSearch));

    this.loadUsers(userSearch);
  }
}
