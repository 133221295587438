import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MatSnackBar, MatCheckboxChange } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData, EventFlag, PermissionFlag, Role, User } from 'src/app/shared/models';
import { DialogService, ErrorService, ProcessManagerService, TokenService, UserService } from 'src/app/shared/services';
import { PasswordMatchValidator } from '../../shared/validators';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html'
})
export class ManageUserComponent {
  // variables

  private eventFlags: EventFlag[];
  public pageMessage: string = '';
  public pageTitle: string = '';
  private permissionFlags: PermissionFlag[];
  public roles: Role[];
  private routing: any;
  public user: User;
  public userForm: FormGroup;

  // properties

  @ViewChild('emailAddressInput', { static: false }) emailAddress: ElementRef;
  @ViewChild('passwordInput', { static: false }) password: ElementRef;

  // constructor

  constructor(private dialogService: DialogService, private errorService: ErrorService, public formBuilder: FormBuilder, private processManagerService: ProcessManagerService,
    private route: ActivatedRoute, private router: Router, public snackBar: MatSnackBar, private tokenService: TokenService, private userService: UserService) {
  }

  // event handlers

  ngOnInit() {
    this.processManagerService.addProcesses(['eventFlags', 'permissionFlags', 'roles', 'user']);

    this.loadEventFlags();
    this.loadPermissionFlags();
    this.loadRoles();
    this.routing = this.route.params.subscribe(params => {
      if (params['id'] != undefined) {
        var id: number = parseInt(params['id']);
        this.loadUser(id);
      }
      else {
        this.createNewUser();
      }
    });
  }

  // business logic

  private addEvents() {
    if ((this.user != null) && (this.eventFlags != null)) {
      var formArray: FormArray = (this.userForm.get('events') as FormArray);

      if (formArray.controls.length == 0) {
        for (var i: number = 0; i < this.eventFlags.length; i++) {
          var eventFlag = this.eventFlags[i];
          var formControl = new FormControl(false);

          if ((this.user.eventFlags & eventFlag.id) == eventFlag.id) {
            formControl.setValue(true);
          }

          formArray.controls.push(formControl);
        }
      }
    }
  }

  private addPermissions() {
    if ((this.user != null) && (this.permissionFlags != null)) {
      var formArray: FormArray = (this.userForm.get('permissions') as FormArray);

      if (formArray.controls.length == 0) {
        for (var i: number = 0; i < this.permissionFlags.length; i++) {
          var permissionFlag = this.permissionFlags[i];
          var formControl = new FormControl(false);

          if ((this.user.permissionFlags & permissionFlag.id) == permissionFlag.id) {
            formControl.setValue(true);
          }

          formArray.controls.push(formControl);
        }
      }
    }
  }

  private addRoles() {
    if ((this.user != null) && (this.roles != null)) {
      var formArray: FormArray = (this.userForm.get('roles') as FormArray);

      if (formArray.controls.length == 0) {
        for (var i: number = 0; i < this.roles.length; i++) {
          var role = this.roles[i];
          var formControl = new FormControl(false);

          if (this.user.roles.find(r => r.id == role.id) != undefined) {
            formControl.setValue(true);
          }

          formArray.controls.push(formControl);
        }
      }
    }
  }

  private addScheduleRoles() {
    if ((this.user != null) && (this.roles != null)) {
      var formArray: FormArray = (this.userForm.get('scheduleRoles') as FormArray);

      if (formArray.controls.length == 0) {
        for (var i: number = 0; i < this.roles.length; i++) {
          var role = this.roles[i];
          var formControl = new FormControl(false);

          if ((this.user.scheduleRoles != undefined) && (this.user.scheduleRoles.find(r => r.id == role.id) != undefined)) {
            formControl.setValue(true);
          }

          formControl.disable();
          if ((this.user.permissionFlags & PermissionFlag.SCHEDULES) == PermissionFlag.SCHEDULES) {
            formControl.enable();
          }

          formArray.controls.push(formControl);
        }
      }
    }
  }

  public changeEmailAddress() {
    this.userForm.get('emailAddress').disable();
    if (this.userForm.get('changeEmailAddress').value) {
      this.userForm.get('emailAddress').enable();
      this.emailAddress.nativeElement.focus();
    }
  }

  public changePassword() {
    this.userForm.get('passwords').disable();
    if (this.userForm.get('changePassword').value) {
      this.userForm.get('passwords').enable();
      this.password.nativeElement.focus();
    }
  }

  public changePermissionFlag($event: MatCheckboxChange, permissionFlag: number) {
    if (permissionFlag == PermissionFlag.SCHEDULES) {
      var formArray: FormArray = (this.userForm.get('scheduleRoles') as FormArray);
      var checked: boolean = $event.checked;

      for (var i: number = 0; i < formArray.controls.length; i++) {
        if (checked) {
          formArray.controls[i].enable();
        }
        else {
          formArray.controls[i].setValue(null);
          formArray.controls[i].disable();
        }
      }
    }
  }

  public clickCancel() {
    this.router.navigate(['/users']);
  }

  private createNewUser() {
    this.user = this.userService.returnNewUser();

    this.initializeForm();
    this.processManagerService.notify('user');

    this.pageTitle = 'Create User';
    this.pageMessage = 'To create a new user, provide the information below and click the <strong>Create User</strong> button.';
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  public getError(controlName, typeName, formGroupName) {
    return this.errorService.getError(this.userForm, controlName, typeName, formGroupName);
  }

  private initializeForm() {
    this.userForm = this.formBuilder.group({
      archived: [this.user.archived, [Validators.required]],
      changeEmailAddress: [false, []],
      changePassword: [false, []],
      emailAddress: [this.user.emailAddress, [Validators.required, Validators.email]],
      events: this.formBuilder.array([]),
      firstName: [this.user.firstName, [Validators.required]],
      lastName: [this.user.lastName, [Validators.required]],
      passwords: this.formBuilder.group({
        password: ['', [Validators.required, Validators.minLength(8)]],
        passwordConfirm: ['', [Validators.required]]
      }),
      permissions: this.formBuilder.array([]),
      roles: this.formBuilder.array([]),
      scheduleRoles: this.formBuilder.array([]),
    });
    this.userForm.get('passwords').setValidators([PasswordMatchValidator]);
    this.userForm.setValidators([]);
    this.userForm.get('changeEmailAddress').enable();
    this.userForm.get('changePassword').enable();
    this.userForm.get('emailAddress').disable();
    this.userForm.get('passwords').disable();
    if (this.user.id == 0) {
      this.userForm.get('changeEmailAddress').setValue(true);
      this.userForm.get('changeEmailAddress').disable();
      this.userForm.get('changePassword').setValue(true);
      this.userForm.get('changePassword').disable();
      this.userForm.get('emailAddress').enable();
      this.userForm.get('passwords').enable();
    }
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  private loadEventFlags() {
    this.userService.loadEventFlags().subscribe(result => {
      this.processManagerService.notify('eventFlags');
      this.eventFlags = result;
      this.addEvents();
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  private loadPermissionFlags() {
    this.userService.loadPermissionFlags().subscribe(result => {
      this.processManagerService.notify('permissionFlags');
      this.permissionFlags = result;
      this.addPermissions();
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  private loadRoles() {
    this.userService.loadRoles().subscribe(result => {
      this.processManagerService.notify('roles');
      this.roles = result;
      this.addRoles();
      this.addScheduleRoles();
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  private loadUser(id: number) {
    this.userService.loadUser(id).subscribe(result => {
      this.user = result;
      this.initializeForm();
      this.addEvents();
      this.addPermissions();
      this.addRoles();
      this.addScheduleRoles();

      this.processManagerService.notify('user');

      this.pageTitle = 'Edit User';
      this.pageMessage = 'To edit the user <strong>' + this.user.name + '</strong>, update the information below and click the <strong>Save User</strong> button.';
    }, error => {
      this.processManagerService.notify('user');
      this.displayError(error.message);
    });
  }

  public submitUser() {
    this.updateUser();

    this.processManagerService.force();

    if (this.user.id == 0) {
      this.userService.createUser(this.user).subscribe(result => {
        this.processManagerService.unforce();
        this.snackBar.open('The user "' + this.user.firstName + ' ' + this.user.lastName + '" has been created.', '', {
          duration: 2000,
        });
        this.router.navigate(['/users']);
      }, error => {
        this.processManagerService.unforce();
        if ((error as HttpErrorResponse).status == 409) {
          this.displayError('Sorry, but the provided email address has already been registered. Please enter a different one.');
          this.userForm.get('emailAddress').setValue('');
          this.emailAddress.nativeElement.focus();
        }
        else {
          this.displayError(error.message);
        }
      });
    }
    else {
      this.userService.modifyUser(this.user).subscribe(result => {
        this.processManagerService.unforce();
        this.snackBar.open('The user "' + this.user.firstName + ' ' + this.user.lastName + '" has been updated.', '', {
          duration: 2000,
        });
        this.router.navigate(['/users']);
      }, error => {
        this.processManagerService.unforce();
        if ((error as HttpErrorResponse).status == 409) {
          this.displayError('Sorry, but the provided email address has already been registered. Please enter a different one.');
          this.userForm.get('emailAddress').setValue('');
          this.emailAddress.nativeElement.focus();
        }
        else {
          this.displayError(error.message);
        }
      });
    }
  }

  private updateUser() {
    var eventsArray: FormArray = (this.userForm.get('events') as FormArray);
    var permissionsArray: FormArray = (this.userForm.get('permissions') as FormArray);
    var rolesArray: FormArray = (this.userForm.get('roles') as FormArray);
    var scheduleRolesArray: FormArray = (this.userForm.get('scheduleRoles') as FormArray);

    this.user.archived = this.userForm.get('archived').value;
    this.user.changeEmailAddress = false;
    this.user.changePassword = false;
    this.user.emailAddress = null;
    this.user.eventFlags = 0;
    for (var i: number = 0; i < eventsArray.controls.length; i++) {
      if ((eventsArray.controls[i] as FormControl).value) {
        this.user.eventFlags += this.eventFlags[i].id;
      }
    }
    this.user.firstName = this.userForm.get('firstName').value;
    this.user.lastName = this.userForm.get('lastName').value;
    this.user.password = null;
    this.user.permissionFlags = 0;
    for (var i: number = 0; i < permissionsArray.controls.length; i++) {
      if ((permissionsArray.controls[i] as FormControl).value) {
        this.user.permissionFlags += this.permissionFlags[i].id;
      }
    }
    this.user.roles = [];
    for (var i: number = 0; i < rolesArray.controls.length; i++) {
      if ((rolesArray.controls[i] as FormControl).value) {
        this.user.roles.push(this.roles[i]);
      }
    }
    this.user.scheduleRoles = [];
    for (var i: number = 0; i < scheduleRolesArray.controls.length; i++) {
      if ((scheduleRolesArray.controls[i] as FormControl).value) {
        this.user.scheduleRoles.push(this.roles[i]);
      }
    }
    if (this.userForm.get('changeEmailAddress').value) {
      this.user.changeEmailAddress = true;
      this.user.emailAddress = this.userForm.get('emailAddress').value;
    }
    if (this.userForm.get('changePassword').value) {
      this.user.changePassword = true;
      this.user.password = this.userForm.get('passwords').get('password').value;
    }
  }
}
