import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { User } from '../../shared/models';
import { DialogService, ErrorService, ProcessManagerService, TokenService, UserService } from '../../shared/services';
import { PasswordMatchValidator } from '../../shared/validators';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html'
})
export class EditProfileComponent {
  // variables

  public pageMessage: string = '';
  public pageTitle: string = '';
  public user: User;
  public userForm: FormGroup;

  // properties

  @ViewChild('emailAddressInput', { static: false }) emailAddress: ElementRef;
  @ViewChild('passwordInput', { static: false }) password: ElementRef;

  // constructors

  constructor(private dialogService: DialogService, private errorService: ErrorService, public formBuilder: FormBuilder, private processManagerService: ProcessManagerService,
    private router: Router, public snackBar: MatSnackBar, private tokenService: TokenService, private userService: UserService) {
  }

  // event handlers

  ngOnInit() {
    this.processManagerService.addProcesses(['user']);
    this.loadUser();
  }

  // business logic

  public changeEmailAddress() {
    this.userForm.get('emailAddress').disable();
    if (this.userForm.get('changeEmailAddress').value) {
      this.userForm.get('emailAddress').enable();
      this.emailAddress.nativeElement.focus();
    }
  }

  public changePassword() {
    this.userForm.get('passwords').disable();
    if (this.userForm.get('changePassword').value) {
      this.userForm.get('passwords').enable();
      this.password.nativeElement.focus();
    }
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  public getError(controlName, typeName, formGroupName) {
    return this.errorService.getError(this.userForm, controlName, typeName, formGroupName);
  }

  private initializeEditProfile() {
    this.initializeForm();
    this.userForm.get('passwords').get('password').setValue(null);
    this.userForm.get('passwords').get('passwordConfirm').setValue(null);
    this.processManagerService.unforce();

    this.pageTitle = 'Edit Profile';
    this.pageMessage = 'To change your password, enter your new password and click the <strong>Change Password</strong> button.';
  }

  private initializeForm() {
    this.userForm = this.formBuilder.group({
      changeEmailAddress: [false, []],
      changePassword: [false, []],
      emailAddress: [this.user.emailAddress, [Validators.required, Validators.email]],
      firstName: [this.user.firstName, [Validators.required]],
      lastName: [this.user.lastName, [Validators.required]],
      passwords: this.formBuilder.group({
        password: ['', [Validators.required, Validators.minLength(8)]],
        passwordConfirm: ['', [Validators.required]]
      }),
    });
    this.userForm.get('passwords').setValidators([PasswordMatchValidator]);
    this.userForm.setValidators([]);
    this.userForm.get('emailAddress').disable();
    this.userForm.get('passwords').disable();
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  private loadUser() {
    this.userService.loadUser(this.tokenService.user.id).subscribe(result => {
      this.processManagerService.unforce();
      this.user = result;
      this.initializeEditProfile();
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  public submitEditProfile() {
    this.updateUser();

    this.processManagerService.force();

    this.userService.modifyUser(this.user).subscribe(result => {
      this.processManagerService.unforce();
      this.snackBar.open('Your profile has been updated.', '', {
        duration: 2000,
      });
      this.router.navigate(['/']);
    }, error => {
      this.processManagerService.unforce();
      if ((error as HttpErrorResponse).status == 409) {
        this.displayError('Sorry, but this email address has already been registered. Please enter a different one.');
        this.userForm.get('emailAddress').setValue('');
        this.emailAddress.nativeElement.focus();
      }
      else {
        this.displayError(error.message);
      }
    });
  }

  private updateUser() {
    this.user.changeEmailAddress = false;
    this.user.changePassword = false;
    this.user.emailAddress = null;
    this.user.firstName = this.userForm.get('firstName').value;
    this.user.lastName = this.userForm.get('lastName').value;
    this.user.password = null;
    if (this.userForm.get('changeEmailAddress').value) {
      this.user.changeEmailAddress = true;
      this.user.emailAddress = this.userForm.get('emailAddress').value;
    }
    if (this.userForm.get('changePassword').value) {
      this.user.changePassword = true;
      this.user.password = this.userForm.get('passwords').get('password').value;
    }
  }
}
