import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '../../shared/models';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent {
  // constructor

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: DialogData, public dialogRef: MatDialogRef<DialogComponent>) {
  }

  // business logic

  public clickCancel(): void {
    this.dialogRef.close({ response: false });
  }

  public clickOk(): void {
    this.dialogRef.close({ response: true });
  }
}
