import { NgModule } from '@angular/core';
import { PhoneNumberPipe } from './shared/pipes/phone-number.pipe';

@NgModule({
  declarations: [
    PhoneNumberPipe,
  ],
  exports: [
    PhoneNumberPipe,
  ]
})
export class PipesModule { }
