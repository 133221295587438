import { Injectable } from '@angular/core';

@Injectable()
export class DateService {
  // constructors

  constructor() {
  }

  // business logic

  public returnCurrentDate() {
    var date: Date = new Date();

    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }
}
