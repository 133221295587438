import { Instrument } from './instrument.model';
import { Role } from './role.model';

export class User {
  // constants

  public static GUEST_EMAIL_ADDRESS: string = 'guest@newlifevictoria.com';
  public static GUEST_FIRST_NAME: string = 'Guest';
  public static GUEST_LAST_NAME: string = 'Musician';

  // variables

  archived: boolean;
  changeEmailAddress: boolean;
  changePassword: boolean;
  dateCreated: Date;
  emailAddress: string;
  eventFlags: number;
  eventSongs: string[];
  firstName: string;
  id: number;
  instrument: Instrument;
  isGuest: boolean;
  isLeader: boolean;
  lastName: string;
  name: string;
  password: string;
  passwordConfirm: string;
  passwordResetKey: string;
  permissionFlags: number;
  roles: Role[];
  scheduleRoles: Role[];
  selected: boolean;
}
