import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ReportSongSummaryComponent } from '../report-song-summary/report-song-summary.component';
import { ReportSongDetailsComponent } from '../report-song-details/report-song-details.component';

@Component({
  selector: 'app-view-reports',
  templateUrl: './view-reports.component.html'
})
export class ViewReportsComponent {
  // variables

  public pageMessage: string = '';
  public pageTitle: string = 'View Reports';
  public selectedReport: number = ViewReportsComponent.REPORT_SONG_SUMMARY;

  // constants

  private static REPORT_SONG_SUMMARY: number = 1;
  private static REPORT_SONG_DETAILS: number = 2;

  // properties

  @ViewChild('report', { static: true, read: ViewContainerRef }) report: ViewContainerRef;

  // constructor

  constructor(private resolver: ComponentFactoryResolver, public snackBar: MatSnackBar) {
  }

  // event handlers

  ngOnInit() {
    this.loadReport()
    this.pageMessage = 'Choose a report, select your criteria, then click <strong>View Report</strong>.'
  }

  // business logic

  public changeReport($event) {
    this.loadReport()
  }

  private loadReport() {
    var componentFactory: any;

    this.report.clear();

    if (this.selectedReport == ViewReportsComponent.REPORT_SONG_SUMMARY) {
      componentFactory = this.resolver.resolveComponentFactory(ReportSongSummaryComponent);
      this.report.createComponent(componentFactory);
    }
    else if (this.selectedReport == ViewReportsComponent.REPORT_SONG_DETAILS) {
      componentFactory = this.resolver.resolveComponentFactory(ReportSongDetailsComponent);
      this.report.createComponent(componentFactory);
    }
  }
}
