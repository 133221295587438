import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { UserLogin } from '../../shared/models';
import { DialogService, ErrorService, ProcessManagerService, TokenService, UserService } from '../../shared/services';
import { DialogInvalidUserComponent } from '../dialog-invalid-user/dialog-invalid-user.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  // variables

  public pageMessage: string = '';
  public pageTitle: string = 'Login';
  public userLogin: UserLogin;
  public userLoginForm: FormGroup;

  // constructor

  constructor(private dialog: MatDialog, private dialogService: DialogService, private errorService: ErrorService, public formBuilder: FormBuilder,
    private processManagerService: ProcessManagerService, private router: Router, private tokenService: TokenService, private userService: UserService) {
  }

  // event handlers

  ngOnInit() {
    if (this.tokenService.isTokenExpired()) {
      this.processManagerService.addProcesses(['login']);
      this.initializeNewUserLogin();
    }
    else {
      this.router.navigate(['/']);
    }
  }

  // business logic

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  public getError(controlName, typeName, formGroupName) {
    return this.errorService.getError(this.userLoginForm, controlName, typeName, formGroupName);
  }

  private initializeForm() {
    this.userLoginForm = this.formBuilder.group({
      emailAddress: [this.userLogin.emailAddress, [Validators.required, Validators.maxLength(80)]],
      password: [this.userLogin.password, [Validators.required, Validators.maxLength(30)]],
      rememberMe: [this.userLogin.rememberMe, []]
    });
    this.userLoginForm.setValidators([]);
  }

  private initializeNewUserLogin() {
    this.pageMessage = 'To log in to the <strong>New Life Worship Portal</strong>, enter your email address and password and click <strong>Login</strong>.';
    this.userLogin = this.userService.returnNewUserLogin();
    this.processManagerService.notify('login');
    this.initializeForm();
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  public submitUserLogin() {
    this.updateUserLogin();

    var rememberMe: boolean = this.userLoginForm.get('rememberMe').value;
    if (rememberMe) {
      this.userService.setRememberedEmailAddress(this.userLogin.emailAddress);
    }
    else {
      this.userService.setRememberedEmailAddress(null);
    }

    this.processManagerService.force();

    this.userService.login(this.userLogin).subscribe(result => {
      this.processManagerService.unforce();
      let token = (<any>result).token;
      this.tokenService.setToken(token);
      this.router.navigate(['/']);
    }, error => {
      this.processManagerService.unforce();if ((error as HttpErrorResponse).status == 401) {
        this.displayError('<p>Sorry, the username you have entered is not valid for system access.</p>');
      }
      else if ((error as HttpErrorResponse).status == 404) {
        const dialogRef = this.dialog.open(DialogInvalidUserComponent, {
          width: '600px'
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result.forgotPassword) {
            this.router.navigate(['/forgot-password', this.userLogin.emailAddress]);
          }
        });
      }
      else {
        this.displayError(error.message);
      }
    });
  }

  private updateUserLogin() {
    this.userLogin.emailAddress = this.userLoginForm.get('emailAddress').value;
    this.userLogin.password = this.userLoginForm.get('password').value;
  }
}
