import { User } from './user.model';

export class ScheduleEmail {
  // variables

  includeSchedule: boolean;
  month: number;
  optionalMessage: string;
  roleId: number;
  standardMessage: string;
  users: User[];
  year: number;
}
