import { Component } from '@angular/core';
import { MonthlySchedule, Schedule, Song } from '../../shared/models';
import { DialogService, ErrorService, ProcessManagerService, ScheduleService, SongService, TokenService } from '../../shared/services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent {
  // variables

  private dateCurrent: Date;
  public loggedInMessage: string = '';
  public monthlySchedules: MonthlySchedule[];
  public newSongs: Song[];
  public pageTitle: string = 'Worship Portal';
  public popularSongs: Song[];
  public scheduleDates: Date[];
  public selectedScheduleDate: Date;
  public upcomingSchedule: Event[];

  // constructor

  constructor(private dialogService: DialogService, private errorService: ErrorService, private processManagerService: ProcessManagerService, private scheduleService: ScheduleService,
    private songService: SongService, private tokenService: TokenService) {
  }

  // event handlers

  ngOnInit() {
    this.processManagerService.addProcesses(['newSongs', 'popularSongs', 'schedule', 'scheduleDates', 'upcomingSchedule']);

    if (!this.tokenService.isTokenExpired()) {
      var loggedInMessage = 'You are currently logged in as <strong>[EmailAddress]</strong>. If you are not <strong>[EmailAddress]</strong> please click the logout button and sign in with your credentials.';
      loggedInMessage = loggedInMessage.replace(/\[EmailAddress\]/g, this.tokenService.user.emailAddress);

      this.loggedInMessage = loggedInMessage;
    }

    let dateCurrent: Date = new Date();
    this.dateCurrent = new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), 1);
    this.loadSchedule();
    this.loadScheduleDates();
    this.loadNewSongs();
    this.loadPopularSongs();
    this.loadUpcomingSchedule();
  }

  // business logic

  public changeSelectedMonth($event) {
    this.dateCurrent = $event.value;
    this.loadSchedule();
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  private loadNewSongs() {
    this.songService.loadNewSongs().subscribe(result => {
      this.processManagerService.notify('newSongs');
      this.newSongs = result;
    }, error => {
      this.processManagerService.notify('newSongs');
      this.displayError(error.message);
    });
  }

  private loadPopularSongs() {
    this.songService.loadPopularSongs().subscribe(result => {
      this.processManagerService.notify('popularSongs');
      this.popularSongs = result;
    }, error => {
      this.processManagerService.notify('popularSongs');
      this.displayError(error.message);
    });
  }

  private loadSchedule() {
    let year: number = this.dateCurrent.getFullYear();
    let month: number = this.dateCurrent.getMonth() + 1;

    this.scheduleService.loadMonthlySchedules(year, month).subscribe(result => {
      this.processManagerService.notify('schedule');
      this.monthlySchedules = result;
    }, error => {
      this.processManagerService.notify('schedule');
      this.displayError(error.message);
    });
  }

  private loadScheduleDates() {
    this.scheduleService.loadScheduleDates().subscribe(result => {
      this.processManagerService.notify('scheduleDates');
      let scheduleDates: Date[] = result as Date[];
      this.scheduleDates = [];
      for (var i: number = 0; i < scheduleDates.length; i++) {
        this.scheduleDates.push(new Date(scheduleDates[i]));
      }

      var dateCurrent: Date = new Date();
      dateCurrent = new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), 1);
      this.selectedScheduleDate = this.scheduleDates.find(s => s.getTime() == dateCurrent.getTime());
      if (this.selectedScheduleDate == undefined) {
        this.selectedScheduleDate = this.scheduleDates[0];
      }
    }, error => {
      this.processManagerService.notify('scheduleDates');
      this.displayError(error.message);
    });
  }

  private loadUpcomingSchedule() {
    this.scheduleService.loadUpcomingSchedule().subscribe(result => {
      this.processManagerService.notify('upcomingSchedule');
      this.upcomingSchedule = result;
    }, error => {
      this.processManagerService.notify('upcomingSchedule');
      this.displayError(error.message);
    });
  }

  public returnMonth(schedule: Schedule): Date {
    return new Date(schedule.year, schedule.month, 1);
  }
}
