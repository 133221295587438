import { Injectable } from '@angular/core';

@Injectable()
export class ProcessManagerService {
  // variables

  public processes: Process[];

  // constructors

  constructor() {
    this.processes = [];
  }

  // business logic

  public addProcess(name: string) {
    const existingProcess: Process = this.processes.find(p => p.name == name);

    if (existingProcess == undefined) {
      var process = new Process();
      process.name = name;
      process.isComplete = false;

      this.processes.push(process);
    }
  }

  public addProcesses(names: string[]) {
    this.processes = [];
    for (var i: number = 0; i < names.length; i++) {
      this.addProcess(names[i]);
    }
  }

  public force() {
    for (var i: number = 0; i < this.processes.length; i++) {
      this.processes[i].isComplete = false;
    }
  }

  public isComplete(): boolean {
    const incompleteProcesses: Process[] = this.processes.filter(p => !p.isComplete);

    if (incompleteProcesses.length > 0) {
      return false;
    }

    return true;
  }

  public notify(name: string) {
    const existingProcess: Process = this.processes.find(p => p.name == name);

    if (existingProcess != undefined) {
      existingProcess.isComplete = true;
    }
  }

  public reset() {
    for (var i: number = 0; i < this.processes.length; i++) {
      this.processes[i].isComplete = false;
    }
  }

  public resetProcess(name) {
    const existingProcess: Process = this.processes.find(p => p.name == name);

    if (existingProcess != undefined) {
      existingProcess.isComplete = false;
    }
  }

  public unforce() {
    for (var i: number = 0; i < this.processes.length; i++) {
      this.processes[i].isComplete = true;
    }
  }
}

export class Process {
  name: string;
  isComplete: boolean;
}
