import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Song, SongSearch } from '../models';

@Injectable()
export class SongService {
  // variables

  private serviceUrl: string = 'api/Songs';

  // constructors

  constructor(private http: HttpClient) {
  }

  // business logic

  public createSong(song: Song): Observable<Song> {
    return this.http.post<Song>(this.serviceUrl + '/CreateSong', song);
  }

  public deleteSong(id: number): Observable<Song> {
    return this.http.delete<Song>(this.serviceUrl + '/DeleteSong/' + id.toString());
  }

  public loadInitialSongLetters(): Observable<string[]> {
    return this.http.get<string[]>(this.serviceUrl + '/LoadInitialSongLetters');
  }

  public loadNewSongs(): Observable<Song[]> {
    return this.http.get<Song[]>(this.serviceUrl + '/LoadNewSongs');
  }

  public loadPopularSongs(): Observable<Song[]> {
    return this.http.get<Song[]>(this.serviceUrl + '/LoadPopularSongs');
  }

  public loadSong(id: number): Observable<Song> {
    return this.http.get<Song>(this.serviceUrl + '/LoadSong/' + id.toString());
  }

  public loadSongChart(id: number): Observable<Blob> {
    return this.http.get<Blob>(this.serviceUrl + '/LoadSongChart/' + id.toString(), { responseType: 'blob' as 'json' });
  }

  public loadSongs(): Observable<Song[]> {
    return this.http.get<Song[]>(this.serviceUrl + '/LoadSongs');
  }

  public modifySong(song: Song): Observable<Song> {
    return this.http.put<Song>(this.serviceUrl + '/ModifySong', song);
  }

  public returnBlob(chart: number[]): Blob {
    var contentType: string = 'application/pdf';

    var data: Uint8Array = new Uint8Array(chart);
    var blob: Blob = new Blob([data], { type: contentType });

    return blob;
  }

  public returnChartFileName(title: string): string {
    if (title == null) {
      return 'chart.pdf';
    }
    else {
      title = title.toLowerCase();
      title = title.replace(/[^A-Za-z0-9\s\/\-]/ig, '');
      title = title.replace(/\s{1,}/ig, '-');
      return title + '.pdf';
    }
  }

  public returnNewSong(): Song {
    var song: Song = new Song();
    song.archived = false;
    song.authors = null;
    song.chart = null;
    song.dateIntroduced = null;
    song.events = null;
    song.id = 0;
    song.title = null;
    song.youtubeUrl = null;

    return song;
  }

  public searchSongs(songSearch: SongSearch): Observable<Song[]> {
    return this.http.post<Song[]>(this.serviceUrl + '/LoadSongs', songSearch);
  }

  public viewChart(blob: Blob, fileName: string) {
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    }
    else {
      var link = document.createElement('a');

      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
