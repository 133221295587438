export class Song {
  // variables

  archived: boolean;
  authors: string;
  chart: number[];
  dateIntroduced: Date;
  dateLastPlayed: Date;
  events: Event[];
  hasChart: boolean;
  id: number;
  key: string;
  lastPlayedEventId: number;
  playCount: number;
  title: string;
  youtubeUrl: string;
}
