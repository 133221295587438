export class SongSearch {
  // constants

  public static ALL: number = -1;
  public static ARCHIVED: number = 0;
  public static ACTIVE: number = 1;

  // variables

  authorSearchTerm: string;
  initialLetter: string;
  songStatus: number = SongSearch.ACTIVE;
  titleSearchTerm: string;

  // business logic

  public isEmpty(): boolean {
    if (((this.authorSearchTerm == null) || (this.authorSearchTerm == '')) && ((this.initialLetter == null) || (this.initialLetter == '')) && (this.songStatus == SongSearch.ALL) && ((this.titleSearchTerm == null) || (this.titleSearchTerm == ''))) {
      return true;
    }

    return false;
  }
}
