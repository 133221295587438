import { MonthlyScheduleUserEvent } from "./monthly-schedule-user-event.model";

export class MonthlyScheduleUser {
  // variables

  events: MonthlyScheduleUserEvent[];
  firstName: string;
  id: number;
  lastName: string;
}
