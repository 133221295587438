import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { Router } from '@angular/router';
import { DialogData, PermissionFlag, Song, SongSearch } from 'src/app/shared/models';
import { DialogService, ProcessManagerService, SongService, TokenService } from 'src/app/shared/services';

@Component({
  selector: 'app-list-songs',
  templateUrl: './list-songs.component.html'
})
export class ListSongsComponent {
  // variables

  public displayedColumns: string[] = ['title', 'authors', 'chart', 'youtube', 'archived', 'edit', 'archive'];
  public initialLetters: string[];
  public isAdministrator: boolean = false;
  public pageMessage: string = '';
  public pageTitle: string = 'Manage Songs';
  private paginator: MatPaginator;
  private searchStorageKey: string = 'songSearch';
  public songs: MatTableDataSource<Song> = new MatTableDataSource<Song>();
  public songSearchForm: FormGroup;
  private sort: MatSort;

  // properties

  @ViewChild(MatPaginator, { static: false }) set matPaginator(value: MatPaginator) {
    this.paginator = value;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort, { static: false }) set matSort(value: MatSort) {
    this.sort = value;
    this.setDataSourceAttributes();
  }

  // constructor

  constructor(private dialogService: DialogService, public formBuilder: FormBuilder, private processManagerService: ProcessManagerService, private router: Router, public snackBar: MatSnackBar,
    private songService: SongService, private tokenService: TokenService) {
    this.isAdministrator = this.tokenService.canAccessArea(PermissionFlag.SONGS);
  }

  // event handlers

  ngOnInit() {
    this.processManagerService.addProcesses(['initialLetters', 'songs']);

    this.loadInitialSongLetters();
    this.initializeSearchForm();
    let songSearch: SongSearch = this.loadSongSearch();
    if (songSearch == null) {
      this.loadSongs(new SongSearch());
    }
    else {
      this.loadSongs(songSearch);
    }
  }

  // business logic

  public changeSearchTerm() {
    if (this.songSearchForm.get('initialLetter').value != null) {
      this.songSearchForm.get('initialLetter').setValue(null);
    }
  }

  public clickArchiveSong(song: Song) {
    var dialogData: DialogData = new DialogData(true, '', this.pageTitle);
    dialogData.text = 'Are you sure you want to archive the song "' + song.title + '"?';

    this.dialogService.openDialog(dialogData).subscribe(result => {
      if (result.response) {
        this.songService.deleteSong(song.id).subscribe(result => {
          this.snackBar.open('The song "' + song.title + '" was successfully archived.', '', {
            duration: 2000,
          });

          let songSearch: SongSearch = this.loadSongSearch();
          if (songSearch == null) {
            this.loadSongs(new SongSearch());
          }
          else {
            this.loadSongs(this.loadSongSearch());
          }
        }, error => {
          this.displayError(error.message);
        });
      }
    });
  }

  public clickCreateNewSong() {
    this.router.navigate(['/songs/create']);
  }

  public clickInitialLetter(letter: string) {
    this.songSearchForm.get('authorSearchTerm').setValue(null);
    this.songSearchForm.get('initialLetter').setValue(letter);
    this.songSearchForm.get('songStatus').setValue(SongSearch.ACTIVE);
    this.songSearchForm.get('titleSearchTerm').setValue(null);

    this.submitSongSearch();
  }

  public clickResetSearch() {
    this.initializeSearchForm();
    this.loadSongs(new SongSearch());
  }

  public clickViewChart(song: Song) {
    let fileName: string = this.songService.returnChartFileName(song.title);

    this.songService.loadSongChart(song.id).subscribe(result => {
      this.songService.viewChart(result, fileName);
    }, error => {
      this.displayError(error.message);
    });
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  private initializeSearchForm() {
    this.songSearchForm = this.formBuilder.group({
      authorSearchTerm: [null, []],
      initialLetter: [null, []],
      songStatus: [SongSearch.ACTIVE, []],
      titleSearchTerm: [null, []],
    });
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  private loadInitialSongLetters() {
    this.processManagerService.resetProcess('initialLetters');
    this.songService.loadInitialSongLetters().subscribe(result => {
      this.processManagerService.notify('initialLetters');
      this.initialLetters = result;
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  private loadSongs(songSearch: SongSearch) {
    this.processManagerService.resetProcess('songs');
    this.songService.searchSongs(songSearch).subscribe(result => {
      this.processManagerService.notify('songs');
      this.loadSongResults(result, songSearch);
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  private loadSongSearch(): SongSearch {
    var songSearch: SongSearch = JSON.parse(sessionStorage.getItem(this.searchStorageKey));

    if (songSearch != null) {
      if (songSearch.authorSearchTerm != null) {
        this.songSearchForm.get('authorSearchTerm').setValue(songSearch.authorSearchTerm);
      }
      if (songSearch.initialLetter != null) {
        this.songSearchForm.get('initialLetter').setValue(songSearch.initialLetter);
      }
      if (songSearch.titleSearchTerm != null) {
        this.songSearchForm.get('titleSearchTerm').setValue(songSearch.titleSearchTerm);
      }
    }

    return songSearch;
  }

  private loadSongResults(result: Song[], songSearch: SongSearch) {
    this.songs = new MatTableDataSource<Song>(result);

    this.pageMessage = 'These are the songs in the database.';
    if (this.songs.data.length == 0) {
      if ((songSearch == null) || songSearch.isEmpty()) {
        this.pageMessage = 'There are no songs in the database.';
      }
      else {
        this.pageMessage = 'There are no songs in the database that match your search criteria.';
      }
    }
  }

  private setDataSourceAttributes() {
    setTimeout(() => {
      this.songs.paginator = this.paginator;
      this.songs.sort = this.sort;
      if (this.sort != undefined) {
        this.sort.sortChange.subscribe((sort: Sort) => {
          this.songs.paginator.firstPage();
        });
      }
    });
  }

  public submitSongSearch() {
    var songSearch: SongSearch = new SongSearch();
    songSearch.authorSearchTerm = this.songSearchForm.get('authorSearchTerm').value;
    songSearch.initialLetter = this.songSearchForm.get('initialLetter').value;
    songSearch.songStatus = this.songSearchForm.get('songStatus').value;
    songSearch.titleSearchTerm = this.songSearchForm.get('titleSearchTerm').value;

    sessionStorage.setItem(this.searchStorageKey, JSON.stringify(songSearch));

    this.loadSongs(songSearch);
  }
}
