export class EventFlag {
  // constants

  public static ALL: number = 0;
  public static SATURDAY: number = 1;
  public static SUNDAY: number = 2;

  // variables

  id: number;
  isSelected: boolean
  name: string;
}
