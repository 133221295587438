import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatSnackBar, MatTableDataSource, MatRadioChange } from '@angular/material';
import { DialogService, ProcessManagerService, ScheduleService, TokenService } from 'src/app/shared/services';
import { DialogData, Role, Schedule } from '../../shared';

@Component({
  selector: 'app-list-schedules',
  templateUrl: './list-schedules.component.html'
})
export class ListSchedulesComponent {
  // variables

  public displayedColumns: string[] = ['month', 'edit', 'notify', 'published', 'publish'];
  private preferenceStorageKey: string = 'scheduleRolePreference';
  public pageMessage: string = '';
  public pageTitle: string = 'Manage Schedules';
  public roleId: number = 0;
  public roles: Role[];
  public schedules: MatTableDataSource<Schedule> = new MatTableDataSource<Schedule>();

  // constructor

  constructor(private datePipe: DatePipe, private dialogService: DialogService, private processManagerService: ProcessManagerService, private scheduleService: ScheduleService, public snackBar: MatSnackBar,
    private tokenService: TokenService) {
  }

  // event handlers

  ngOnInit() {
    this.processManagerService.addProcesses(['roles', 'schedules']);

    this.loadUserScheduleRoles();
  }

  // business logic

  public changeRole($event: MatRadioChange) {
    this.processManagerService.resetProcess('schedules');
    this.loadSchedules();
  }

  public clickModifyPublishStatus(schedule: Schedule) {
    if (schedule.published) {
      var dialogData: DialogData = new DialogData(true, '', 'Edit Schedule');
      dialogData.text = 'Are you sure you revoke this schedule?';

      this.dialogService.openDialog(dialogData).subscribe(result => {
        if (result.response) {
          this.revokeSchedule(schedule);
        }
      });
    }
    else {
      this.publishSchedule(schedule);
    }
  }

  private displayError(message: string) {
    this.dialogService.displayError(message, this.pageTitle);
  }

  public isLoading(): boolean {
    return !this.processManagerService.isComplete();
  }

  private loadSchedules() {
    this.scheduleService.loadSchedules(this.roleId).subscribe(result => {
      this.processManagerService.notify('schedules');
      this.schedules = new MatTableDataSource<Schedule>(result);
      localStorage.setItem(this.preferenceStorageKey, this.roleId.toString());
      this.pageMessage = 'To manage a schedule, choose a month from the list below.';
    }, error => {
      this.processManagerService.notify('schedules');
      this.displayError(error.message);
    });
  }

  private loadUserScheduleRoles() {
    this.scheduleService.loadUserScheduleRoles(this.tokenService.user.id).subscribe(result => {
      this.processManagerService.notify('roles');
      this.roles = result;

      if (this.roles.length == 0) {
        this.processManagerService.unforce();
        this.pageMessage = 'You do not have access to any schedules.';
      }
      else if (this.roles.length >= 1) {
        this.roleId = this.roles[0].id;
        if (localStorage.getItem(this.preferenceStorageKey) != undefined) {
          var roleId: number = parseInt(localStorage.getItem(this.preferenceStorageKey));
          if (this.roles.find(r => r.id == roleId) != undefined) {
            this.roleId = roleId;
          }
        }
        this.loadSchedules();
      }
    }, error => {
      this.processManagerService.notify('roles');
      this.displayError(error.message);
    });
  }

  private publishSchedule(schedule: Schedule) {
    schedule.published = !schedule.published;

    this.processManagerService.resetProcess('schedules');
    this.scheduleService.modifySchedulePublishStatus(schedule).subscribe(result => {
      this.processManagerService.notify('schedules');
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }

  public returnMonth(schedule: Schedule): string {
    var date = new Date(schedule.year, schedule.month - 1, 1);
    return this.datePipe.transform(date, 'MMMM yyyy');
  }

  public returnUrl(schedule: Schedule, type: string): string {
    var role: string = 'music';
    if (this.roleId == Role.MEDIA) {
      role = 'media';
    }
    else if (this.roleId == Role.SOUND) {
      role = 'sound';
    }

    return '/schedules/' + type + '/' + role + '/' + schedule.year.toString() + '/' + schedule.month.toString();
  }

  private revokeSchedule(schedule: Schedule) {
    schedule.published = !schedule.published;

    this.processManagerService.resetProcess('schedules');
    this.scheduleService.modifySchedulePublishStatus(schedule).subscribe(result => {
      this.processManagerService.notify('schedules');
    }, error => {
      this.processManagerService.unforce();
      this.displayError(error.message);
    });
  }
}
