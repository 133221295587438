import { AbstractControl } from '@angular/forms';

export function PasswordStrengthValidator(control: AbstractControl) {
  // business logic

  var lowercase = new RegExp(/(?=.*[a-z])/);
  var uppercase = new RegExp(/(?=.*[A-Z])/);
  var numbers = new RegExp(/(?=.*[0-9])/);
  var characters = new RegExp(/(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/);
  var hasLowercase = lowercase.test(control.value);
  var hasUppercase = uppercase.test(control.value);
  var hasNumbers = numbers.test(control.value);
  var hasCharacters = characters.test(control.value);

  if ((control == null) || (control.value == null) || (control.value.length < 8) || (hasLowercase && hasUppercase && (hasNumbers || hasCharacters))) {
    return null;
  }

  return { invalidStrength: true };
}
