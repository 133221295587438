import { Event } from './event.model';
import { Role } from './role.model';

export class Schedule {
  // variables

  events: Event[];
  id: number;
  month: number;
  published: boolean;
  role: Role;
  year: number;
}
