import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialog-invalid-user',
  templateUrl: './dialog-invalid-user.component.html'
})
export class DialogInvalidUserComponent {
  // constructor

  constructor(public dialogRef: MatDialogRef<DialogInvalidUserComponent>) {
  }

  // business logic

  public clickOk(): void {
    this.dialogRef.close({ response: true });
  }

  public forgotPassword(): void {
    this.dialogRef.close({ forgotPassword: true });
  }
}
